import Lenis from "@studio-freight/lenis";
import { useEffect, useLayoutEffect } from "react";
import { useScrollStore, useStore } from "@state/store";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export const allSections = [
	"Story",
	"Building",
	"Location",
	"Gallery",
	"Team",
	"Contact"
];

export const LenisScroller = () => {
	const setScrollValue = useScrollStore(state => state.setScrollValue);
	const setScrollVelocity = useScrollStore(state => state.setScrollVelocity);
	const setSection = useStore(state => state.setSection);
	const setLenisInstance = useStore(s => s.setLenisInstance);
	const launchPopupSeen = useStore(s => s.launchPopupSeen);

	function debounce(func, wait) {
		let timeout;
		return function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				func.apply(this, args);
			}, wait);
		};
	}

	const checkActiveSection = debounce(() => {
		for (const section of allSections) {
			const elem = document.querySelector(`.section-${section.toLowerCase()}`);
			if (elem) {
				const rect = elem.getBoundingClientRect();
				const isMostlyVisible =
					rect.top < window.innerHeight * 0.5 &&
					rect.bottom > window.innerHeight * 0.5;
				if (isMostlyVisible) {
					setSection(section);
					break;
				}
			}
		}
	}, 10);

	useLayoutEffect(() => {
		checkActiveSection();
		const isMobile =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			);
		const lenis = new Lenis({
			duration: 1.2,
			easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
			touchMultiplier: isMobile ? 1 : 2,
			infinite: false,
			gestureOrientation: "vertical",
			smoothWheel: !isMobile,
			wheelMultiplier: 1,
			touchInertiaMultiplier: 1.5
		});
		setLenisInstance(lenis);
		lenis.on("scroll", (e: any) => {
			ScrollTrigger.update;
			setScrollValue(e.progress);
			setScrollVelocity(e.velocity);
			checkActiveSection();
		});

		function raf(time: number) {
			lenis.raf(time);
			requestAnimationFrame(raf);
		}

		requestAnimationFrame(raf);

		return () => {
			lenis.destroy();
		};
	}, []);

	// useEffect(() => {
	// 	const lenis = useStore.getState().lenisInstance;
	// 	if (!lenis) return;
	// 	if (launchPopupSeen) {
	// 		lenis.stop();
	// 	} else {
	// 		lenis.start();
	// 	}
	// }, [launchPopupSeen]);

	return <></>;
};
