import DigitalExperience from "./les-bordes-estate/DigitalExperience";
import Essence from "./les-bordes-estate/Essence";
import HolisticApproach from "./les-bordes-estate/HolisticApproach";
import NewStandard from "./les-bordes-estate/NewStandard";
import Story from "./les-bordes-estate/Story";
import NoProject from "./NoProject";

const projectSections = {
	"les-bordes-estate": [
		{
			Component: HolisticApproach,
			label: "A Holistic Approach to Timeless Luxury"
		},
		{
			Component: Story,
			label: "Understanding the Story Behind the Residences"
		},
		{ Component: Essence, label: "Capturing the Essence of Six Senses" },
		{
			Component: DigitalExperience,
			label: "An Elevated Digital Experience with Luna"
		},
		{ Component: NewStandard, label: "A New Standard in Property Showcase" }
	],
	"norton-folgate": [{ Component: NoProject, label: "Coming Soon." }],
	"1515-surf": [
		{ Component: NoProject, label: "Coming Soon." }
		// { Component: NoProject, label: "Bringing Coney Island’s Vibe to Life" },
		// { Component: NoProject, label: "A Visual Story Rooted in Local Culture" },
		// {
		// 	Component: NoProject,
		// 	label: "Interactive Animation for an Immersive Experience"
		// },
		// { Component: NoProject, label: "Capturing the Energy of the Mermaid Parade" },
		// { Component: NoProject, label: "A Seamless Blend of Past and Present" },
		// { Component: NoProject, label: "A Visual Experience that Stands Out" }
	],
	roshn: [{ Component: NoProject, label: "Coming Soon." }],
	origin: [{ Component: NoProject, label: "Coming Soon." }],
	tsx: [
		{ Component: NoProject, label: "Coming Soon." }
		// { Component: NoProject, label: "A Metaverse Experience of Times Square" },
		// { Component: NoProject, label: "Building Times Square in the Metaverse" },
		// { Component: NoProject, label: "Seamless Access Anywhere, Anytime" },
		// { Component: NoProject, label: "Creating Immersive Sales Experiences" },
		// { Component: NoProject, label: "Streamlining Collaboration with 3D Workflows" },
		// { Component: NoProject, label: "Impact" }
	],
	thresholds: [{ Component: NoProject, label: "Coming Soon." }],
	"the-boardwalk": [
		{ Component: NoProject, label: "Coming Soon." }
		// { Component: NoProject, label: "Crafting Immersive Visuals and Experiences" },
		// { Component: NoProject, label: "Bringing the Vision to Life with CG Imagery" },
		// { Component: NoProject, label: "A Seamless Sales Gallery Experience" },
		// { Component: NoProject, label: "Technical Scoping and UX Design" },
		// { Component: NoProject, label: "Creating a Lasting Impact" }
	],
	"720": [
		{ Component: NoProject, label: "Coming Soon." }
		// 	{ Component: NoProject, label: "Visualizing a New Standard of Luxury Living" },
		// 	{ Component: NoProject, label: "Realizing the Vision through CG Imagery" },
		// 	{ Component: NoProject, label: "An Interactive Sales Gallery Experience" },
		// 	{ Component: NoProject, label: "Technical Scoping and User Experience Design" },
		// 	{ Component: NoProject, label: "A Timeless Experience for Discerning Buyers" }
	]
};

export default projectSections;
