import { useStore } from "@state/store";

export default function DarkModeSVG({ ...props }) {
	const darkMode = useStore(state => state.darkMode);
	const setDarkMode = useStore(state => state.setDarkMode);
	return (
		<svg
			viewBox="0 0 398 398"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				cursor: "pointer",
				pointerEvents: "auto",
				zIndex: 10
			}}
			onClick={() => {
				setDarkMode(!darkMode);
			}}
			{...props}>
			<path
				style={{
					opacity: darkMode ? 0 : 1,
					transition: "opacity 0.2s ease-in-out"
				}}
				d="M166.694 158.616C166.694 148.303 168.209 137.881 172.078 129C146.356 140.197 129 166.466 129 196.306C129 236.453 161.547 269 201.694 269C231.534 269 257.803 251.644 269 225.922C260.119 229.791 249.688 231.306 239.384 231.306C199.238 231.306 166.694 198.762 166.694 158.616Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
			<path
				style={{
					opacity: !darkMode ? 0 : 1,
					transition: "opacity 0.2s ease-in-out"
				}}
				d="M165.655 156.425L154.2 144.982L145.227 153.995L156.618 165.438L165.655 156.425ZM148.091 192.607H129V205.393H148.091V192.607ZM205.364 129H192.636V147.858H205.364V129ZM252.773 153.995L243.8 144.982L232.409 156.425L241.382 165.438L252.773 153.995ZM232.345 241.575L243.736 253.082L252.709 244.068L241.255 232.626L232.345 241.575ZM249.909 192.607V205.393H269V192.607H249.909ZM199 160.644C177.936 160.644 160.818 177.84 160.818 199C160.818 220.16 177.936 237.356 199 237.356C220.064 237.356 237.182 220.16 237.182 199C237.182 177.84 220.064 160.644 199 160.644ZM192.636 269H205.364V250.142H192.636V269ZM145.227 244.005L154.2 253.018L165.591 241.511L156.618 232.498L145.227 244.005Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
		</svg>
	);
}
