import { useRef } from "react";

import map from "/images/projects/les-bordes-estate/map.webp";
import { useStore } from "@state/store";
import CSS from "./css/story.module.css";
import SHARED from "./css/shared.module.css";

function Story({ ...props }) {
	const { sectionIndex } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const active = sectionIndex === 2;
	const wrapperRef = useRef<HTMLDivElement>(null);

	return (
		<div
			{...props}
			style={{
				...props.style,
				padding: "25vh 15px"
			}}
			className={SHARED.sectionWrapper}
			ref={wrapperRef}>
			<div
				style={{
					opacity: active ? 1 : 0,
					transform: active ? "translateY(0%)" : "translateY(10%)",
					filter: active ? "blur(0px)" : "blur(10px)"
				}}
				className={`${CSS.mapImage} ${SHARED.transitionReveal}`}>
				<img src={map} />
			</div>
			<div
				className={`${SHARED.copyWrapperToLeft} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					transform: active ? "translateY(0%)" : "translateY(-30%)"
				}}>
				<h1 className={`projectTitle`}>
					Understanding the Story Behind the Residences
				</h1>
				<p className={`projectCopy`}>
					Our journey began with a deep dive into the design philosophy behind
					the residences. Through a series of discovery and storyboarding
					sessions, we collaborated closely with Six Senses and the design team.
					The goal was to distil their vision into a suite of content that would
					resonate with their discerning clientele. This exploration led to the
					creation of visual assets that captured the essence of refined living,
					in harmony with the natural beauty of the Loire Valley.
				</p>
			</div>
		</div>
	);
}

export default Story;
