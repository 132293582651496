import { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import DefaultLayout from "./DefaultLayout"

import "./index.css"

function App() {

	useEffect(() => {
		document.addEventListener("contextmenu", (e) => e.preventDefault())
	}, [])
	return (
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<DefaultLayout />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
