import { useStore } from "@state/store";
export default function LinkedInSVG({ ...props }) {
	const darkMode = useStore(state => state.darkMode);
	return (
		<svg
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M46.0011 0H4.27232C1.99219 0 0 1.64062 0 3.89397V45.7154C0 47.981 1.99219 50 4.27232 50H45.9888C48.2813 50 50 47.9676 50 45.7154V3.89397C50.0134 1.64062 48.2813 0 46.0011 0ZM15.4989 41.6775H8.33594V19.4063H15.4989V41.6775ZM12.1652 16.0201H12.1138C9.82143 16.0201 8.33705 14.3136 8.33705 12.1775C8.33705 10.0022 9.86049 8.33594 12.2042 8.33594C14.548 8.33594 15.9821 9.98996 16.0335 12.1775C16.0324 14.3136 14.548 16.0201 12.1652 16.0201ZM41.6775 41.6775H34.5145V29.5C34.5145 26.5826 33.4721 24.5893 30.8806 24.5893C28.9007 24.5893 27.7288 25.9286 27.2076 27.2333C27.0123 27.702 26.9598 28.3404 26.9598 28.9922V41.6775H19.7969V19.4063H26.9598V22.5056C28.0022 21.0212 29.6306 18.885 33.4196 18.885C38.1217 18.885 41.6786 21.9844 41.6786 28.6663L41.6775 41.6775Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
		</svg>
	);
}
