import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";

import NavLogo from "@components/nav-logo";
import LogoSVG from "@components/svg/logo";
import DarkModeSVG from "@components/svg/dark-mode";
import StaggeredText from "@components/staggered-text";

import { useStore } from "@state/store";
import CSS from "./nav.module.css";
import VMILogo from "@components/nav-logo/VMILogo";

export default function Nav() {
	const lenis = useStore(s => s.lenisInstance);
	const navOpen = useStore(s => s.navOpen);
	const darkMode = useStore(s => s.darkMode);
	const setNavOpen = useStore(s => s.setNavOpen);
	const handleNav = useStore(s => s.handleNav);

	const menuRef = useRef<HTMLDivElement>(null);

	const { pathname } = useLocation();
	const navigator = useNavigate();
	const routes = [
		{ label: "Home", path: "/" },
		{ label: "Projects", path: "/projects" },
		{ label: "Contact", path: "/contact" }
	];

	const active = p => (p === "/" ? p === pathname : pathname.includes(p));

	function navigate(path) {
		handleNav(() => {
			setNavOpen(false);
			navigator(path);
		});
	}

	useEffect(() => {
		if (!menuRef.current) return;
		if (navOpen || pathname !== "/") {
			lenis && lenis.stop();
		} else {
			lenis && lenis.start();
		}
		gsap.to(menuRef.current, {
			autoAlpha: navOpen ? 1 : 0,
			duration: 0.8
		});
	}, [navOpen, pathname]);
	return (
		<>
			<nav className={CSS.navWrapper}>
				<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					<VMILogo
						style={{
							height: "50%",
							width: "7rem",
							marginLeft: "2rem",
							cursor: "pointer",
							pointerEvents: "auto"
						}}
						onClick={() => {
							navigator("/");
							setNavOpen(false);
						}}
					/>
				</div>
				<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					<NavLogo
						style={{
							minWidth: "clamp(60px, 16rem, 80px)",
							maxWidth: "clamp(60px, 16rem, 80px)",
							cursor: "pointer",
							pointerEvents: "all",
							transform: "scale(0.8)"
						}}
						onClick={() => {
							setNavOpen(!navOpen);
						}}
					/>
					<DarkModeSVG
						style={{
							minWidth: "clamp(60px, 16rem, 80px)",
							maxWidth: "clamp(60px, 16rem, 80px)",
							cursor: "pointer",
							pointerEvents: "all"
						}}
					/>
				</div>
			</nav>
			<div
				className={CSS.navMenuWrapper}
				ref={menuRef}
				style={{
					backgroundColor: darkMode
						? "rgba(26, 26, 26, 0.8)"
						: "rgba(255, 255, 255, 0.5)"
				}}>
				{routes.map(obj => {
					return (
						<h1
							key={obj.path}
							onClick={() => navigate(obj.path)}
							style={{
								opacity: active(obj.path) ? 1 : 0.5,
								color: !darkMode ? "var(--black)" : "var(--white)"
							}}>
							<StaggeredText
								text={obj.label}
								cue={navOpen}
								duration={navOpen ? 1 : 0.2}
								delay={navOpen ? 1 : 0.2}
							/>
						</h1>
					);
				})}
				<h1
					onClick={() => {
						setNavOpen(false);
					}}
					style={{
						opacity: 0.5,
						color: !darkMode ? "var(--black)" : "var(--white)",
						fontSize: "clamp(10px, 2rem, 35px)"
					}}>
					<StaggeredText
						text={"X"}
						cue={navOpen}
						duration={navOpen ? 1 : 0.2}
						delay={navOpen ? 1 : 0.2}
					/>
				</h1>
			</div>
		</>
	);
}
