import styles from './page.module.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useEffect, useRef } from 'react'
import SplitType from 'split-type'
import Vimeo from '@u-wave/react-vimeo'
import { useMediaQuery } from 'react-responsive'

const images = {
  'Postmark London': {
    minWidth: '95%',
    title: 'POSTMARK',
    subtitle: 'LONDON',
    date: '2023',
  },
  '1-5 London Wall London 1': {
    minWidth: '80%',
    transform: 'translateX(10rem)',
    title: '1-5 London Wall',
    subtitle: 'LONDON',
    date: '2023',
  },
  'Plucks Brisbane': {
    minWidth: '70%',
    transform: 'translateX(-20rem)',
    title: 'Plucks Road',
    subtitle: 'BRISBANE',
    date: '2023',
  },
  'YY London': {
    minWidth: '70%',
    transform: 'translateX(-6rem)',
    title: 'YY',
    subtitle: 'LONDON',
    date: '2023',
  },
  'Hunters Point Manhattan': {
    minWidth: '80%',
    transform: 'translateX(12rem)',
    title: "Hunter's Point",
    subtitle: 'MANHATTAN',
    date: '2023',
  },
  'Noir San Francisco 2': {
    minWidth: '95%',
    title: 'Noir',
    subtitle: 'SAN FRANCISCO',
    date: '2023',
  },
  'Las Palmer Miami': {
    minWidth: '95%',
    title: 'PALMA, INDIAN CREEK',
    subtitle: 'MIAMI',
    date: '2023',
  },
}

export default function SectionThree() {
  const contextRef = useRef(null)
  const mobile = useMediaQuery({
    query: '(max-width: 1200px)',
  })

  useEffect(() => {
    // const p2 = new SplitType(
    //   '.p-2',
    //   // { charClass: 'letter-mask' }
    // )
    gsap.registerPlugin(ScrollTrigger)
    const ctx = gsap.context(() => {
      const imgs: HTMLElement[] = gsap.utils.toArray('.three-img')
      const imgPs: HTMLElement[] = gsap.utils.toArray('.three-img p')
      imgs.forEach((img, i) => {
        const ps = new SplitType(img.querySelectorAll('p'))
        gsap.set(img, {
          opacity: 0,
          scale: gsap.utils.random(0.95, 1.1),
          y: mobile ? 0 : gsap.utils.random(0, 200),
          x: gsap.utils.random(-40, 40),
        })
        gsap.set(`.three-img p`, {
          opacity: 1,
        })
        gsap.set(ps.chars, {
          opacity: 0,
          x: 5,
        })
        gsap.to(img, {
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          ease: 'power2.inOut',
          scrollTrigger: {
            immediateRender: false,
            scrub: true,
            trigger: img,
            start: 'top 85%',
            end: '+=70%',
          },
        })
        gsap.to(ps.chars, {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          ease: 'power2.inOut',
          scrollTrigger: {
            immediateRender: false,
            scrub: true,
            trigger: img,
            start: 'center 70%',
            end: '+=30%',
          },
        })
      })
    }, contextRef)

    return () => ctx.revert()
  }, [])
  return (
    <section className={styles[`three-div`]} ref={contextRef} style={{ gridAutoFlow: 'row', rowGap: '4rem' }}>
      <div
        className={styles[`three-div-video`]}
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: '16/9',
          display: 'grid',
          placeItems: 'center',
          overflow: 'hidden',
          marginBottom: '200px',
        }}
      >
        <Vimeo
          video='https://vimeo.com/831961783/c5acaf4b05?share=copy'
          // background
          autoplay
          muted
          responsive
          playsInline
          showByline={false}
          showTitle={false}
          style={{ width: '100%', height: 'auto', transform: 'scale(1.02)' }}
        />
      </div>
      {Object.keys(images).map((image, i) => {
        return (
          <div
            className='three-img'
            key={i}
            style={{
              position: 'relative',
              opacity: 0,
              overflow: 'hidden',
              //   backgroundColor: '#fcfafa',
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateRows: 'minmax(0, 1fr) minmax(0, 0.07fr)',
              minHeight: '50vw',
              placeItems: 'center',
              translateX: '100px',
              ...images[image],
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'grid',
                overflow: 'hidden',
                placeItems: 'center',
              }}
            >
              <img
                alt='VMI Studio'
                src={`/images/${image}.webp`}
                style={{
                  objectFit: 'cover',
                  zIndex: 1,
                  overflow: 'hidden',
                  padding: '1rem 1rem 0rem 1rem',
                }}
              />
            </div>
            <div
              style={{
                placeSelf: 'end center',
                display: 'grid',
                placeItems: 'center',
                height: '100%',
                width: '100%',
                padding: '0rem 1rem 0rem 1rem',
                gridAutoFlow: 'column',
                zIndex: 2,
              }}
            >
              <div
                style={{
                  placeSelf: 'center start',
                  display: 'grid',
                  gridAutoFlow: 'row',
                  textTransform: 'uppercase',
                }}
              >
                <p style={{ opacity: 0 }}>{images[image].title}</p>
                {/* <p style={{ opacity: 0, fontSize: '0.6rem' }}>{images[image].subtitle}</p> */}
              </div>
              <p style={{ placeSelf: 'center end' }}>{images[image].subtitle}</p>
            </div>
          </div>
        )
      })}
    </section>
  )
}
