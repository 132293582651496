import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";

import SVGIcons from "@components/svg-icons";

import basket from "/images/projects/les-bordes-estate/branded-basket.webp";
import castle from "/images/projects/les-bordes-estate/castle.webp";
import golf from "/images/projects/les-bordes-estate/golf-course.webp";
import hero from "/images/projects/les-bordes-estate/hero.webp";
import lake from "/images/projects/les-bordes-estate/lake.webp";
import picnic from "/images/projects/les-bordes-estate/picnic.webp";
import flower from "/images/projects/les-bordes-estate/yellow-flower.webp";
import { useStore } from "@state/store";
import CSS from "./css/holistic.module.css";
import SHARED from "./css/shared.module.css";

const images = [castle, flower, picnic, basket, lake, golf, hero];

function HolisticApproach({ ...props }) {
	const { sectionIndex, darkMode } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const active = sectionIndex === 1;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const initialScroll = useRef(null);
	const height = useRef(null);

	function parallax(e) {
		if (!active) return;
		if (initialScroll.current === null) {
			initialScroll.current = e.target.scrollTop;
			height.current = wrapperRef.current.scrollHeight;
		}
		const mapped = Math.min(
			gsap.utils.mapRange(
				initialScroll.current,
				height.current,
				0,
				1,
				e.target.scrollTop
			),
			1
		);
		gsap.set(".holl-img", {
			opacity: mapped,
			transform: `scale(${0.98 + mapped * 0.02}) rotate(${2 - mapped * 2}deg)`,
			filter: `blur(${10 - mapped * 10}px) saturate(${mapped})`,
			stagger: 0.05
		});
	}
	function reset() {
		if (!active) return;
		initialScroll.current = null;
		gsap.to(".holl-img", {
			opacity: 0,
			transform: `scale(0.98) rotate(2deg)`,
			filter: `blur(10px) saturate(0)`,
			duration: 0.8
		});
	}

	useLayoutEffect(() => {
		const scroller = document.getElementById("project-container");
		scroller.addEventListener("scroll", parallax);
		return () => {
			scroller.removeEventListener("scroll", parallax);
			reset();
		};
	}, [active]);
	return (
		<div {...props} className={SHARED.sectionWrapper} ref={wrapperRef}>
			<div
				className={`${SHARED.copyWrapperCentered} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)"
				}}>
				<SVGIcons
					icon="les-bordes-logo"
					stroke="none"
					fill={!darkMode ? "var(--black)" : "var(--white)"}
					// fillOpacity={active ? 0.025 : 0}
					className={CSS.logo}
				/>
				<h1 className={`projectTitle ${CSS.title}`}>
					A Holistic Approach to Timeless Luxury
				</h1>
				<p className={`projectCopy ${CSS.copy}`}>
					Six Senses approached us with a clear vision: to showcase their new
					collection of branded residences in the Loire Valley, a development
					that seamlessly blends luxury with the beauty of France’s premier wine
					region. Our task was to craft a visual narrative that would embody the
					elegance of the Six Senses brand, highlighting not just the residences
					but the lifestyle they offer.
				</p>
			</div>
			<div className={CSS.imagesGrid}>
				{images.map((src, i) => {
					return (
						<img
							key={`les-bordes-img-hollistic-${i}`}
							className="holl-img"
							src={src}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default HolisticApproach;
