import { Routes, Route } from "react-router-dom"

import routes from "@routes/routes"
import { Fragment } from "react/jsx-runtime"
import Nav from "@components/nav/nav"

function DefaultLayout() {
	return (
		<Fragment>
			<Routes>
				{routes.map((route, idx) => {
					return (
						route.element && (
							<Route
								key={route.name}
								path={route.path}
								element={<route.element />}
							/>
						)
					)
				})}
			</Routes>
			<Nav />
		</Fragment>
	)
}

export default DefaultLayout
