import { useState, useRef, Fragment, useLayoutEffect } from "react";
import gsap from "gsap";

import { projects } from "@data/projectsData";
import CSS from "./menuCopy.module.css";
import { useStore } from "@state/store";

function ProjectMenuCopy() {
	const { activeProject } = useStore(s => ({
		activeProject: s.activeProject
	}));
	const [firstLoad, setFirstLoad] = useState(true);
	const [activeCopy, setActiveCopy] = useState(projects[activeProject].mainPar);

	const copyRef = useRef<HTMLParagraphElement>(null);

	const duration = 0.3;
	const ease = "power1.inOut";

	useLayoutEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			return;
		}
		if (!copyRef.current) return;
		gsap.to(copyRef.current, {
			opacity: 0,
			onComplete: () => {
				setActiveCopy(projects[activeProject].mainPar);
				gsap.to(copyRef.current, {
					opacity: 0.5,
					delay: 0.1,
					duration,
					ease
				});
			},
			duration,
			ease
		});
	}, [activeProject]);

	return (
		<div className={CSS.copyWrapper}>
			<p className={CSS.copy} ref={copyRef}>
				{activeCopy.map((txt, i) => (
					<Fragment key={`p${activeProject}${i}`}>{txt} </Fragment>
				))}
			</p>
		</div>
	);
}

export default ProjectMenuCopy;
