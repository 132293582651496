import { useRef, useEffect, useState } from "react";
import gsap from "gsap";

import ProjectMenuTitle from "@components/project-menu-title";
import ProjectsMenu from "@components/projects-menu";
import NavAnimationWrapper from "@components/nav-animation-wrapper";
import ProjectMenuImg from "@components/project-menu-img";
import ProjectMenuCopy from "@components/project-menu-copy";
import Project from "@pages/project";

import { projects, projectKeys } from "@data/projectsData";
import { useStore } from "@state/store";
import CSS from "./projects.module.css";

function Projects() {
	const {
		activeProject,
		projectPopup,
		darkMode,
		navigating,
		navDurationSec,
		setNavigating
	} = useStore(s => ({
		activeProject: s.activeProject,
		projectPopup: s.projectPopup,
		darkMode: s.darkMode,
		navigating: s.navigating,
		navDurationSec: s.navDurationSec,
		setNavigating: s.setNavigating
	}));
	const [firstLoad, setFirstLoad] = useState(true);
	const projectData = projects[activeProject];
	const projectIndex = projectKeys.indexOf(activeProject);

	const projectMenuRef = useRef<HTMLDivElement>(null);
	const projectContentRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const timeline = gsap.timeline();
	const tmlConfig = {
		duration: 1,
		ease: "power1.inOut"
	};

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			return;
		}
		if (!videoRef.current || !projectMenuRef.current) return;
		timeline.to(
			projectMenuRef.current,
			{
				autoAlpha: projectPopup ? 0 : 1,
				...tmlConfig
			},
			projectPopup ? 0 : 0.8
		);
		timeline.to(
			videoRef.current,
			{
				opacity: projectPopup ? 1 : 0.03,
				filter: projectPopup
					? "blur(0px) saturate(1)"
					: "blur(10px) saturate(0)",
				...tmlConfig
			},
			projectPopup ? 0.8 : 0
		);
	}, [projectPopup]);

	return (
		<NavAnimationWrapper
			state={navigating}
			durationSec={navDurationSec}
			delay={100}
			setState={v => setNavigating(v)}
			className={CSS.projectsWrapper}
			style={{
				backgroundColor: darkMode ? "var(--black)" : "var(--white)",
				color: !darkMode ? "var(--black)" : "var(--white)",
				position: "fixed",
				width: "100%",
				height: "100%"
			}}>
			<video
				className={CSS.mainVideo}
				src={projectData.video}
				muted
				autoPlay
				playsInline
				loop
				ref={videoRef}
			/>
			<div ref={projectMenuRef}>
				<ProjectsMenu />
				<div className={CSS.projectPreview}>
					<ProjectMenuTitle />
					<ProjectMenuImg />
					<div className={CSS.footer}>
						<div className={CSS.location}>
							<span>{projectData.location.map(txt => `${txt} `)}</span>
							<span>
								{projectIndex + 1} / {projectKeys.length}
							</span>
						</div>
					</div>
					<ProjectMenuCopy />
				</div>
			</div>
			<Project ref={projectContentRef} />
		</NavAnimationWrapper>
	);
}

export default Projects;
