import { useEffect, useRef, useState } from "react";
import Popup from "@components/popup";
import { useStore } from "@state/store";
import styles from "./launch-popup.module.css";

// Sound On SVG component
const SoundOnIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11 5L6 9H2V15H6L11 19V5Z"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 12C17.0039 13.3308 16.4774 14.6024 15.54 15.54"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.07 4.93C20.9447 6.80527 21.9979 9.34835 21.9979 12C21.9979 14.6517 20.9447 17.1947 19.07 19.07"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

// Sound Off SVG component
const SoundOffIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11 5L6 9H2V15H6L11 19V5Z"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M23 9L17 15"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17 9L23 15"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

function LaunchPopup() {
	const { setLocalState } = useStore(s => ({
		setLocalState: s.setLocalState
	}));
	const [showPopup, setShowPopup] = useState(true);
	const [isMuted, setIsMuted] = useState(true);
	const videoRef = useRef<HTMLVideoElement>(null);
	const lenis = useStore(s => s.lenisInstance);

	const toggleSound = () => {
		if (videoRef.current) {
			videoRef.current.muted = !videoRef.current.muted;
			setIsMuted(!isMuted);
		}
	};

	return (
		<Popup
			openState={showPopup}
			setOpenState={setShowPopup}
			durationMS={400}
			closeColor={"var(--white)"}
			onClose={() => {
				setLocalState({
					launchPopupSeen: true
				});
				lenis && lenis.scrollTo(0, { immediate: true });
			}}>
			<div className={styles.wrapper}>
				<button onClick={toggleSound} className={styles.soundToggle}>
					{isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
				</button>
				<video
					className={styles.video}
					autoPlay
					muted={isMuted}
					playsInline
					loop
					ref={videoRef}
					src={`https://vmicdn.uk/vmijourney1.mp4`}
				/>
				<div className={styles.content}>
					<h1>VMI Studio</h1>
					<p style={{ width: "60%" }}>
						We're excited to announce that we are now a part of{" "}
						<a
							style={{
								color: "var(--white)",
								cursor: "pointer",
								pointerEvents: "auto"
							}}
							href="https://journey.world/"
							target="_blank"
							rel="noopener noreferrer">
							Journey,
						</a>{" "}
						<br />a multidimensional design studio crafting experiences that
						unfold across the physical, digital and virtual realms.
					</p>
					<br />
					<br />
					<button
						onClick={() => {
							setShowPopup(false);
							lenis && lenis.scrollTo(0, { immediate: true });
						}}
						className={styles.button}>
						Explore <span>→</span>
					</button>
				</div>
			</div>
		</Popup>
	);
}

export default LaunchPopup;
