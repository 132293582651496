import { useRef } from "react";

import { useStore } from "@state/store";
import luna from "/videos/les-bordes-estate/sales-suite.mp4";
import CSS from "./css/standard.module.css";
import SHARED from "./css/shared.module.css";

function NewStandard({ ...props }) {
	const { sectionIndex, darkMode } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const videoRef = useRef<HTMLVideoElement>(null);
	const mutePrompt = useRef<HTMLSpanElement>(null);
	const active = sectionIndex === 5;
	return (
		<div {...props} className={`${SHARED.sectionWrapper}`}>
			<div
				className={`${SHARED.copyWrapperToLeft} ${SHARED.transitionReveal}`}
				style={{ opacity: active ? 1 : 0, textAlign: "left" }}>
				<h1
					className={`projectTitle ${SHARED.transitionReveal}`}
					style={{
						transform: active ? "translateX(0%)" : "translateX(-5%)",
						width: "100%"
					}}>
					A New Standard in Property Showcase
				</h1>
				<p
					className={`projectCopy ${SHARED.transitionReveal}`}
					style={{ transform: active ? "translateX(0%)" : "translateX(5%)" }}>
					Through this collaboration, we helped Six Senses articulate a
					compelling story of luxury living in the Loire Valley. The combination
					of immersive visuals and an innovative digital platform provided a
					powerful narrative that not only reflected the brand’s prestigious
					reputation but also set a new benchmark for property showcases in the
					luxury sector.
				</p>
			</div>
			<div
				className={`${CSS.videoWrapper} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)"
				}}>
				<video
					muted
					autoPlay
					loop
					playsInline
					controls={false}
					src={luna}
					ref={videoRef}
					onClick={() => {
						videoRef.current.muted = !videoRef.current.muted;
						mutePrompt.current.innerText = `Click to toggle sound ${
							videoRef.current.muted ? "ON." : "OFF."
						}`;
					}}
				/>
				<span ref={mutePrompt}>Click to toggle sound ON.</span>
			</div>
		</div>
	);
}

export default NewStandard;
