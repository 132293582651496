import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import SplitType from "split-type";
import { gsap } from "gsap";

import styles from "./page.module.css";

export default function SectionHero() {
	// const mobile = useMediaQuery({
	//   query: '(max-width: 1200px)',
	// })
	// const darkMode = useStore((state) => state.darkMode)
	const contextRef = useRef(null);
	const bottomRef = useRef(null);

	useEffect(() => {
		const p1 = new SplitType(".hero-p-1");
		const p2 = new SplitType(".hero-p-2");
		const p3 = new SplitType(".hero-p-3");
		const p4 = new SplitType(".hero-p-4");
		gsap.registerPlugin(ScrollTrigger);
		const ctx = gsap.context(() => {
			gsap.set([p1.chars, p2.chars, p3.chars, p4.chars], {
				opacity: 0,
				scale: 0.95,
				y: -5
			});
			gsap.set(".hero-div-cta a", { opacity: 0, x: -5 });
			gsap.set([".hero-p-1, .hero-p-2, .hero-p-3, .hero-p-4, .hero-div-cta"], {
				opacity: 1
			});
			// gsap.to('#hero-div', {
			//   autoAlpha: 1,
			//   duration: 0.1,
			// })
			gsap
				.timeline({ repeat: -1 })
				.to(
					[p1.chars],
					{
						opacity: 1,
						scale: 1,
						x: 0,
						y: 0,
						duration: 1,
						stagger: 0.04,
						ease: "power2.inOut"
					},
					"<"
				)

				.to([p1.chars], {
					opacity: 0,
					delay: 1,
					duration: 0.3,
					stagger: 0.01,
					ease: "power1.inOut",
					x: 2,
					scale: 0.8
				})
				.to([p2.chars], {
					opacity: 1,
					scale: 1,
					x: 0,
					y: 0,
					duration: 1,
					stagger: 0.04,
					ease: "power2.inOut"
				})
				.to([p2.chars], {
					opacity: 0,
					delay: 1,
					duration: 0.3,
					stagger: 0.01,
					ease: "power1.inOut",
					x: 5
				})
				.to([p3.chars], {
					opacity: 1,
					scale: 1,
					x: 0,
					y: 0,
					duration: 1,
					stagger: 0.04,
					ease: "power2.inOut"
				})
				.to([p3.chars], {
					opacity: 0,
					delay: 1,
					duration: 0.3,
					stagger: 0.01,
					ease: "power1.inOut",
					x: 5
				})
				.to([p4.chars], {
					opacity: 1,
					scale: 1,
					x: 0,
					y: 0,
					duration: 1,
					stagger: 0.04,
					ease: "power2.inOut"
				})
				.to([p4.chars], {
					opacity: 0,
					delay: 1,
					duration: 0.3,
					stagger: 0.01,
					ease: "power1.inOut",
					x: 5
				});

			gsap
				.timeline({ repeat: -1 })
				.set(".test", { x: 0, opacity: 0, scale: 1 })
				.to(".test", {
					opacity: 1,
					duration: 1
				})
				.to(
					".test",
					{
						x: -3400,
						ease: "none",
						duration: 22.5
					},
					"<"
				)
				.to(
					".test",
					{
						opacity: 0,
						duration: 0.3,
						delay: 22.5
						// x: 20,
						// scale: 0.9,
					},
					"<"
				);

			gsap.to(".wrap", {
				scale: 0.8,
				y: 100,
				opacity: 0.5,
				ease: "none",
				scrollTrigger: {
					trigger: bottomRef.current,
					start: "bottom +70%",
					end: "+=50%",
					scrub: true
				}
			});

			gsap.to(".hero-div-cta a", {
				opacity: 1,
				x: 0,
				duration: 2,
				stagger: 0.3,
				delay: 1
			});
		}, contextRef);

		return () => ctx.revert();
	}, []);
	return (
		<>
			<section className={`${styles[`hero-div`]}`} ref={contextRef}>
				<div
					id="hero-div"
					className="wrap"
					style={{
						height: "100%",
						width: "100%",
						display: "grid",
						placeItems: "center",
						overflow: "hidden",
						zIndex: 1
						// opacity: 0,
						// visibility: 'hidden',
					}}>
					<video
						className={`hero-video ${styles[`hero-video`]}`}
						poster="/images/bg2.webp"
						src="/videos/bg2.mp4"
						autoPlay
						muted
						playsInline
						loop
					/>
					<div
						className={styles[`hero-overlay`]}
						style={{ color: "var(--white)" }}
					/>
					<p
						className={`hero-p-1 ${styles[`hero-p-1`]}`}
						style={{
							opacity: 0,
							color: "var(--white)",
							gridAutoFlow:
								// mobile ? 'row' :
								"column"
						}}>
						Visual.
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Motion.
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />}  */}
						Interactive.
					</p>
					<p
						className={`hero-p-2 ${styles[`hero-p-2`]}`}
						style={{
							opacity: 0,
							color: "var(--white)",
							gridAutoFlow:
								//  mobile ? 'row' :
								"column"
						}}>
						Interactive
						{/* <br /> */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Sales
						{/* <br /> */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Applications
					</p>
					<p
						className={`hero-p-3 ${styles[`hero-p-3`]}`}
						style={{
							opacity: 0,
							color: "var(--white)",
							gridAutoFlow:
								// mobile ? 'row' :
								"column"
						}}>
						Immersion
						{/* <br /> */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Room
						{/* <br />  */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Experiences
					</p>
					<p
						className={`hero-p-4 ${styles[`hero-p-4`]}`}
						style={{
							opacity: 0,
							color: "var(--white)",
							gridAutoFlow:
								// mobile ? 'row' :
								"column"
						}}>
						{/* <br /> */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						CGIs
						{/* <br />  */}
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						and
						{/* {mobile ? <br /> : <span style={{ marginRight: '1rem' }} />} */}
						Animations
					</p>
					<div
						className={`hero-div-cta ${styles[`hero-div-cta`]}`}
						style={{ opacity: 0 }}>
						<a
							style={{ textDecoration: "none" }}
							rel="noopener noreferrer"
							target="_blank"
							href="mailto:info@vmistudio.com">
							<button
								style={{
									// all: 'unset',
									padding: "1.1rem 1.8rem",
									borderRadius: "10px",

									// backgroundColor: darkMode ? 'var(--black)' : '#fff',
									border: "2px solid var(--white)",
									color: "var(--white)",
									// textTransform: 'uppercase',
									fontFamily: "700",
									fontSize: "1.6rem",
									letterSpacing: "-0.1rem",
									cursor: "pointer",
									pointerEvents: "auto"
								}}>
								Get in touch →
							</button>
						</a>

						<a
							style={{
								color: "var(--white)",
								textDecoration: "none",
								fontWeight: 400,
								fontSize: "1.2rem",
								letterSpacing: "0.02rem",
								cursor: "pointer",
								pointerEvents: "auto"
							}}
							rel="noopener noreferrer"
							target="_blank"
							href="tel:+44 (0) 208 8773 287">
							+44 (0) 208 8773 287
						</a>
					</div>
					<div
						style={{
							placeSelf: "end start",
							gridColumn: "1 / -1",
							gridRow: "1 / -1",
							overflow: "hidden",
							padding: "2rem 0rem",
							display: "grid",
							placeItems: "start center",
							gridAutoFlow: "column",
							zIndex: 2
						}}>
						<div
							className="test"
							style={{
								opacity: 0,
								width: "400rem",
								display: "grid",
								placeSelf: "start",
								placeItems: "start"
							}}>
							<p
								style={{
									fontSize: "1.3rem",
									zIndex: 2,
									display: "grid",
									placeItems: "start center",
									textAlign: "left",
									textTransform: "uppercase",
									letterSpacing: "0.13rem",
									color: "var(--white)",
									transform: "translateX(6rem)"
								}}>
								CGI • Film • UAV/Drone • Helicopter/Light Aircraft Filming
								• Photogrammetry • Digital Twins • Gamification • Artificial
								Intelligence (AI) • Animation • Rotoscoping • Virtual Reality
								(VR) • Augmented Reality (AR) • Application builds • Software
								engineering • Light Detection and Ranging (LiDAR) • Immersion
								Rooms • Digital Sales Platform • Presentation tool •
								Experiential Content • VR Tours • Creative Strategy & Direction
							</p>
						</div>
					</div>
				</div>
			</section>
			<div ref={bottomRef} style={{ height: "0%" }} />
		</>
	);
}
