import styles from "./page.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useEffect, useRef } from "react";
import SplitType from "split-type";
import { useStore } from "@state/store";

export default function SectionTwo() {
	const contextRef = useRef(null);
	const darkMode = useStore(s => s.darkMode);

	useEffect(() => {
		const p1 = new SplitType(
			".two-p-1"
			// { charClass: 'letter-mask' }
		);
		const p2 = new SplitType(
			".two-p-2"
			// { charClass: 'letter-mask' }
		);
		gsap.registerPlugin(ScrollTrigger);
		const ctx = gsap.context(() => {
			gsap.set([p1.chars, p2.chars], { opacity: 0, scale: 0.9, y: 20, x: -5 });
			gsap.set([".two-div-2 img"], { opacity: 0, scale: 0.7, y: 40, x: -5 });
			gsap.set([".two-p-1, .two-p-2, .two-div-2"], { opacity: 1 });
			gsap
				.timeline()
				.to([p1.chars, p2.chars], {
					opacity: 1,
					scale: 1,
					y: 0,
					x: 0,
					stagger: 0.4,
					duration: 1,
					ease: "power1.inOut",
					scrollTrigger: {
						immediateRender: false,
						scrub: true,
						trigger: contextRef.current,
						start: "top 65%",
						end: "+=50%"
					}
				})
				.to(".two-div-2 img", {
					opacity: 1,
					scale: 1,
					x: 0,
					y: 0,
					stagger: 0.4,
					scrollTrigger: {
						immediateRender: false,
						scrub: true,
						trigger: ".two-div-2",
						start: "top 85%",
						end: "+=40%"
					}
				});
		}, contextRef);

		return () => ctx.revert();
	}, []);
	return (
		<section className={styles[`two-div`]} ref={contextRef}>
			<div className={styles[`two-div-1`]}>
				<p
					className={`two-p-1 ${styles[`two-p-1`]}`}
					style={{
						opacity: 0,
						fontSize: "1.4rem",
						lineHeight: "1.4rem",
						letterSpacing: "0.05rem",
						textTransform: "uppercase",
						height: "100%"
					}}>
					VMI Studio
				</p>
				<p
					className={`two-p-2 ${styles[`two-p-2`]}`}
					style={{
						opacity: 0,
						fontSize: "3.1rem",
						lineHeight: "3.1rem",
						letterSpacing: "-0.2rem",
						height: "100%",
						width: "100%"
					}}>
					A global creative technology studio which adds value by leveraging
					innovation to solve problems. We unshackle the limits of imagination
					and <span style={{ fontWeight: "700" }}>redefine</span> the{" "}
					<span style={{ fontWeight: "700" }}>art of possible</span>.
				</p>
			</div>
			<div
				className={`two-div-2 ${styles[`two-div-2`]}`}
				style={{ opacity: 0 }}>
				<img
					alt="VMI Studio"
					src="/images/logos/svgTishman.svg"
					style={{ filter: !darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgFoster.svg"
					style={{ filter: !darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgTSX.svg"
					style={{ filter: darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgRothschild.svg"
					style={{ filter: darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgBritishLand.svg"
					style={{ filter: darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgBerkely.svg"
					style={{ filter: darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgArgent.svg"
					style={{ filter: !darkMode ? "invert(1)" : "invert(0)" }}
				/>
				<img
					alt="VMI Studio"
					src="/images/logos/svgBatterseaPowerStation.svg"
					style={{ filter: darkMode ? "invert(1)" : "invert(0)" }}
				/>
				{/* <img alt='VMI Studio' src='/images/logos/svgSomersetHouse.svg' /> */}
			</div>
		</section>
	);
}
