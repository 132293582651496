import { useRef, useState, useEffect } from "react";
import gsap from "gsap";

import lyfestile from "/videos/les-bordes-estate/lifestyle.mp4";
import fireplace from "/images/projects/les-bordes-estate/fireplace.webp";
import hero from "/images/projects/les-bordes-estate/hero.webp";
import house from "/images/projects/les-bordes-estate/house.webp";
import living from "/images/projects/les-bordes-estate/living.webp";
import master from "/images/projects/les-bordes-estate/master.webp";
import materials from "/images/projects/les-bordes-estate/materials.webp";
import room from "/images/projects/les-bordes-estate/room.webp";
import plot from "/images/projects/les-bordes-estate/plotfinder.webp";
import clay from "/images/projects/les-bordes-estate/plotfinderclay.webp";
import { useStore } from "@state/store";
import CSS from "./css/essence.module.css";
import SHARED from "./css/shared.module.css";

const images = [living, hero, fireplace, house, master, materials, room];

function Essence({ ...props }) {
	const { sectionIndex, darkMode } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const [activeImg, setActiveImg] = useState(0);
	const [fullImg, setFullImg] = useState(false);
	const active = sectionIndex === 3;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const svgRef = useRef<SVGSVGElement>(null);
	const maskRef = useRef<SVGRectElement>(null);
	const markerRef = useRef<SVGGElement>(null);
	const fullImgRef = useRef<HTMLImageElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const mutePrompt = useRef<HTMLSpanElement>(null);

	function handleDrag(e) {
		e.stopPropagation();
		e.preventDefault();
		const { clientX } = e;
		const point = svgRef.current.createSVGPoint();
		point.x = clientX;
		const svgPoint = point.matrixTransform(
			svgRef.current.getScreenCTM().inverse()
		);

		const offset = Math.min(Math.max(svgPoint.x, 0), 1920);
		gsap.set(maskRef.current, {
			attr: {
				x: offset
			}
		});
		gsap.set(markerRef.current, {
			attr: {
				transform: `translate(${offset},0)`
			}
		});
	}

	function handleDown() {
		document.addEventListener("pointermove", handleDrag);
		document.addEventListener("pointerup", handleUp);
		document.addEventListener("pointerleave", handleUp);
	}

	function handleUp() {
		document.removeEventListener("pointermove", handleDrag);
		document.removeEventListener("pointerup", handleUp);
		document.removeEventListener("pointerleave", handleUp);
	}

	useEffect(() => {
		if (!fullImgRef.current) return;
		gsap.to(fullImgRef.current, {
			autoAlpha: fullImg ? 1 : 0,
			duration: 0.8,
			ease: "power1.inOut"
		});
	}, [fullImg]);

	useEffect(() => {
		if (fullImg && !active) {
			setFullImg(false);
			videoRef.current.play();
			videoRef.current.muted = true;
		}
	}, [active]);
	return (
		<div {...props} className={`${SHARED.sectionWrapper}`} ref={wrapperRef}>
			<div
				className={`${SHARED.copyWrapperCentered} ${SHARED.transitionReveal}`}
				style={{ opacity: active ? 1 : 0 }}>
				<h1
					className={`projectTitle ${CSS.title} ${SHARED.transitionReveal}`}
					style={{ transform: active ? "translateX(0%)" : "translateX(-5%)" }}>
					Capturing the Essence of Six Senses
				</h1>
				<p
					className={`projectCopy ${CSS.copy} ${SHARED.transitionReveal}`}
					style={{ transform: active ? "translateX(0%)" : "translateX(5%)" }}>
					The result was a carefully curated collection of visuals—striking
					imagery, fluid moving images for digital and social platforms,
					immersive 3D tours, and a cinematic film. Each element was crafted to
					convey the tranquillity and sophistication of these luxury homes,
					drawing in potential buyers with a glimpse into the Six Senses
					lifestyle. From the grandeur of the architecture to the serene,
					natural surroundings, every frame was designed to evoke a sense of
					timeless luxury.
				</p>
			</div>
			<div
				className={`${CSS.SvgWrapper} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)"
				}}>
				<svg x={0} y={0} width="100%" viewBox="0 0 1920 1080" ref={svgRef}>
					<image href={plot} preserveAspectRatio="xMidYMid meet" />
					<image
						href={clay}
						preserveAspectRatio="xMidYMid meet"
						mask={"url(#clay-mask)"}
					/>
					<g transform="translate(960,0)" ref={markerRef}>
						<line
							x1={0}
							y1={0}
							x2={0}
							y2={1081}
							stroke={!darkMode ? "var(--white)" : "var(--black)"}
							strokeWidth={5}
							vectorEffect="non-scaling-stroke"
						/>
						<circle
							cx={0}
							cy={540}
							r={25}
							fill={
								!darkMode
									? "rgba(var(--white-rgb), 1)"
									: "rgba(var(--black-rgb), 1)"
							}
							stroke={!darkMode ? "var(--white)" : "var(--black)"}
							strokeWidth={2}
							onPointerDown={e => {
								e.preventDefault();
								handleDown();
							}}
						/>
						<polyline
							points="-5,525 -18,540  -5,555"
							stroke={darkMode ? "var(--white)" : "var(--black)"}
							strokeWidth={3}
							fill="none"
						/>
						<polyline
							points="5,525 18,540 5,555"
							stroke={darkMode ? "var(--white)" : "var(--black)"}
							strokeWidth={3}
							fill="none"
						/>
					</g>
					<defs>
						<mask id="clay-mask">
							<rect
								x={960}
								y={0}
								width={1920}
								height={1080}
								fill="white"
								ref={maskRef}
							/>
						</mask>
					</defs>
				</svg>
			</div>
			<div
				className={`${CSS.imagesGridEss} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)"
				}}>
				<video
					muted
					autoPlay
					loop
					playsInline
					controls={false}
					src={lyfestile}
					ref={videoRef}
					onClick={() => {
						videoRef.current.muted = !videoRef.current.muted;
						mutePrompt.current.innerText = `Click to toggle sound ${
							videoRef.current.muted ? "ON." : "OFF."
						}`;
					}}
				/>
				<span ref={mutePrompt}>Click to toggle sound ON.</span>
				{images.map((src, i) => {
					return (
						<img
							key={`les-bordes-img-essence-${i}`}
							src={src}
							onClick={() => {
								setActiveImg(i);
								setTimeout(() => {
									videoRef.current.pause();
									setFullImg(true);
								}, 200);
							}}
						/>
					);
				})}
				<div
					style={{
						backgroundColor: darkMode
							? "rgba(var(--black-rgb), 0.3)"
							: "rgba(var(--white-rgb), 0.3)"
					}}
					onClick={() => {
						setFullImg(false);
						setTimeout(() => {
							videoRef.current.play();
						}, 800);
					}}
					ref={fullImgRef}>
					<img src={images[activeImg]} />
				</div>
			</div>
		</div>
	);
}

export default Essence;
