import styles from "./page.module.css";
import { useStore } from "@state/store";
import NavAnimationWrapper from "@components/nav-animation-wrapper";
import { LenisScroller } from "../../lenis/lenis";

export default function ClientWrapper({ children }) {
	const darkMode = useStore(state => state.darkMode);
	const navigating = useStore(state => state.navigating);
	const navDurationSec = useStore(state => state.navDurationSec);
	const setNavigating = useStore(state => state.setNavigating);
	return (
		<NavAnimationWrapper
			state={navigating}
			durationSec={navDurationSec}
			delay={100}
			setState={v => setNavigating(v)}
			style={{
				position: "relative",
				width: "100%",
				maxHeight: "100%",
				overflowY: "auto",
				WebkitOverflowScrolling: "touch"
			}}>
			<main
				className={styles.main}
				style={{
					backgroundColor: darkMode ? "var(--black)" : "var(--white)",
					color: !darkMode ? "var(--black)" : "var(--white)"
				}}>
				{children}
			</main>
			<LenisScroller />
		</NavAnimationWrapper>
	);
}
