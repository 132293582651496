import InstagramSVG from "@components/svg/socials/instagram";
import LinkedInSVG from "@components/svg/socials/linkedin";
import EmailSVG from "@components/svg/socials/email";

import styles from "./page.module.css";
import { useStore } from "@state/store";

export default function Footer() {
	const darkMode = useStore(state => state.darkMode);
	return (
		<footer
			className={styles.footer}
			style={{
				backgroundColor: !darkMode ? "var(--white)" : "var(--black)",
				borderTop: darkMode ? "2px solid rgba(var(--white-rgb), 0.5)" : "2px solid rgba(var(--black-rgb), 0.5)"
			}}>
			<div
				style={{
					display: "grid",
					width: "70%",
					placeItems: "center start",
					gridAutoFlow: "row",
					rowGap: "0.4rem"
				}}>
				<p
					style={{
						fontSize: "0.94rem",
						textTransform: "uppercase",
						letterSpacing: "0.03rem",
						fontWeight: 600
					}}>
					VMI Studio
				</p>
				{/* <div style={{ height: '0rem' }} /> */}
				<p>Unit CC3.19, 3 Kennington Park Rd, London SW9 6DE</p>
				<div
					style={{
						// gridColumn: 1,
						// gridRow: 2,
						display: "grid",
						height: "50%",
						width: "30%",
						placeItems: "center",
						gridAutoFlow: "column",
						columnGap: "2rem"
					}}>
					<a
						style={{
							color: !darkMode ? "var(--black)" : "var(--white)",
							textDecoration: "none",
							height: "100%"
						}}
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.instagram.com/vmistudio/">
						<InstagramSVG />
					</a>
					<a
						style={{
							color: !darkMode ? "var(--black)" : "var(--white)",
							textDecoration: "none",
							maxHeight: "100%",
							maxWidth: "100%"
						}}
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.linkedin.com/company/vmi-studio">
						<LinkedInSVG style={{ maxHeight: "100%", maxWidth: "100%" }} />
					</a>
					<a
						style={{
							color: !darkMode ? "var(--black)" : "var(--white)",
							textDecoration: "none",
							maxHeight: "100%",
							maxWidth: "100%"
						}}
						rel="noopener noreferrer"
						target="_blank"
						href="mailto:info@vmistudio.com">
						<EmailSVG style={{ maxHeight: "100%", maxWidth: "100%" }} />
					</a>
				</div>
			</div>

			<div
				style={{
					display: "grid",
					width: "70%",
					placeItems: "center start",
					gridAutoFlow: "row",
					rowGap: "0.4rem"
				}}>
				<a
					style={{
						color: !darkMode ? "var(--black)" : "var(--white)",
						textDecoration: "none",
						width: "auto"
						// fontWeight: 600
					}}
					rel="noopener noreferrer"
					target="_blank"
					href="mailto:info@vmistudio.com">
					info@vmistudio.com
				</a>
				<a
					style={{
						color: !darkMode ? "var(--black)" : "var(--white)",
						textDecoration: "none",
						width: "auto"
						// fontWeight: 600
					}}
					rel="noopener noreferrer"
					target="_blank"
					href="tel:+44 (0) 208 8773 287">
					+44 (0) 208 8773 287
				</a>
			</div>

			{/* <div style={{ height: '100%', width: '100%', gridColumn: '1 / -1', gridRow: 3 }} /> */}
		</footer>
	);
}
