"use client";
import React from "react";

import NavAnimationWrapper from "@components/nav-animation-wrapper";

import { useStore } from "@state/store";
import CSS from "./contact.module.css";

function Contact() {
	const darkMode = useStore(s => s.darkMode);
	const navigating = useStore(s => s.navigating);
	const navDurationSec = useStore(s => s.navDurationSec);
	const setNavigating = useStore(s => s.setNavigating);
	return (
		<div className={CSS.wrapper}>
			<NavAnimationWrapper
				state={navigating}
				durationSec={navDurationSec}
				delay={100}
				setState={v => setNavigating(v)}>
				<video
					className={CSS.bgVideo}
					poster="/images/bg2.webp"
					src="/videos/bg2.mp4"
					autoPlay
					muted
					playsInline
					loop
				/>
				<div
					style={{
						backgroundColor: darkMode
							? "rgba(var(--black-rgb), 0.8)"
							: "rgba(var(--white-rgb), 0.5)",
						backdropFilter: "blur(10px)",
						color: !darkMode ? "var(--black)" : "var(--white)",
						cursor: "pointer"
					}}
					className={`${CSS.infoWrapper} selectable`}>
					<h1>VMI STUDIO</h1>
					<h2>New York</h2>
					<h3>1140 Broadway</h3>
					<h3>New York, NY 10001</h3>
					<h3>Phone +1 212 929 5657</h3>
					<br />
					<br />
					<h2>London</h2>
					<h3>Cranmer House, CR01, 39 Brixton Rd</h3>
					<h3>London SW9 6DZ</h3>
					<h3>Phone +44 20 8877 3287</h3>
					<br />
					<br />
					<a
						style={{
							color: !darkMode ? "var(--black)" : "var(--white)",
							textDecoration: "underline",
							width: "auto"
							// fontWeight: 600
						}}
						rel="noopener noreferrer"
						target="_blank"
						href="mailto:info@vmistudio.com">
						info@vmistudio.com
					</a>
				</div>
			</NavAnimationWrapper>
		</div>
	);
}

export default Contact;
