import { Fragment, useEffect, useRef, ReactNode, useLayoutEffect, CSSProperties } from 'react'
import gsap from 'gsap'

type TNavAnimationWrapperProps = {
  children?: ReactNode
  onStart?: (...args: any[]) => void
  onComplete?: (...args: any[]) => void
  state: boolean
  setState: (x: boolean) => void
  durationSec: number
  delay?: number
  style?: CSSProperties
  [x: string]: unknown
}

function NavAnimationWrapper({
  children,
  onStart = undefined,
  onComplete = undefined,
  state,
  setState,
  durationSec,
  delay = 0,
  style = {},
  ...props
}: TNavAnimationWrapperProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!wrapperRef.current) return
    gsap.set(wrapperRef.current, { opacity: 0 })
  }, [])

  useEffect(() => {
    if (wrapperRef.current) {
      onStart && onStart()
      gsap.to(wrapperRef.current, {
        autoAlpha: state ? 0 : 1,
        delay: state ? 0 : durationSec * 0.5,
        onComplete: () => {
          if (onComplete) {
            setTimeout(() => {
              onComplete()
            }, 200)
          }
        },
        duration: durationSec,
        ease: 'power1.out',
      })
      if (state) {
        setTimeout(() => {
          setState(false)
        }, durationSec * 1100)
      }
    }
  }, [state])
  return (
    <Fragment>
      <div
        style={{
          ...style,
          visibility: 'hidden',
        }}
        {...props}
        ref={wrapperRef}
      >
        {children}
      </div>
    </Fragment>
  )
}

export default NavAnimationWrapper
