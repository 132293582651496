import { useRef } from "react";

import AnimatedIcon from "@components/animated-icon";
import SVGIcons from "@components/svg-icons";

import { useStore } from "@state/store";
import CSS from "./les-bordes-estate/css/holistic.module.css";
import SHARED from "./les-bordes-estate/css/shared.module.css";
import LogoSVG from "@components/svg/logo";
import NavLogo from "@components/nav-logo";

function NoProject({ ...props }) {
	const { sectionIndex, darkMode } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const active = sectionIndex === 1;
	const wrapperRef = useRef<HTMLDivElement>(null);

	return (
		<div
			{...props}
			style={{ ...props.style, minHeight: "100%" }}
			className={SHARED.sectionWrapper}
			ref={wrapperRef}>
			<div
				className={`${SHARED.copyWrapperCentered} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)",
					top: "50%",
					transform: "translateY(-50%)"
				}}>
				<NavLogo animated={false} width="50%" strokeWidth={0.5} />
				<h1
					className={`projectTitle ${CSS.title}`}
					style={{ transform: "translateY(-50%)", opacity: 0.5 }}>
					Coming soon.
				</h1>
			</div>
		</div>
	);
}

export default NoProject;
