import ClientWrapper from "@components/client-wrapper/client-wrapper";
import Footer from "@components/footer/footer";
import LaunchPopup from "@components/section-hero/launch-popup";
import SectionHero from "@components/section-hero/section-hero";
import SectionThree from "@components/section-three/section-three";
import SectionTwo from "@components/section-two/section-two";

function Landing() {
	return (
		<ClientWrapper>
			<LaunchPopup />
			<SectionHero />
			<SectionTwo />
			<SectionThree />
			<Footer />
		</ClientWrapper>
	);
}

export default Landing;
