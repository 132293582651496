import { forwardRef, useEffect, useRef } from "react";
import gsap from "gsap";

import Popup from "@components/popup";
import ProjectControl from "@components/project-control";

import projectSections from "@components/project-sections";
import { projects } from "@data/projectsData";
import { useStore } from "@state/store";
import CSS from "./project.module.css";

const Project = forwardRef<HTMLDivElement>(function Project(props, ref) {
	const { projectPopup, activeProject, darkMode, sectionIndex, setLocalState } =
		useStore(s => ({
			projectPopup: s.projectPopup,
			activeProject: s.activeProject,
			darkMode: s.darkMode,
			sectionIndex: s.sectionIndex,
			setLocalState: s.setLocalState
		}));
	const scrollRef = useRef(0);
	const data = projects[activeProject];
	const darkModeVariant = {
		backgroundColor: darkMode ? "var(--black)" : "var(--white)",
		color: !darkMode ? "var(--black)" : "var(--white)",
		transitionProperty: "background-color, color",
		transitionDuration: "0.4s",
		transitionTimingFunction: "ease-in-out"
	};
	// useEffect(() => {
	// 	console.log(sectionIndex)
	// }, [sectionIndex])
	return (
		<Popup
			openState={projectPopup}
			setOpenState={() => null}
			durationMS={400}
			closeColor={"transparent"}
			onClose={() => {
				setLocalState({
					sectionIndex: 0
				});
			}}>
			<div className={CSS.projectWrapper} ref={ref} id="project-container">
				<ProjectControl sections={projectSections[activeProject]} />
				<div className={CSS.section} data-project-section={0} />
				{projectSections[activeProject].map(({ Component, label }, i) => {
					return (
						<Component
							key={i}
							data-project-section={i + 1}
							style={{
								...darkModeVariant
							}}
						/>
					);
				})}
				<div
					className={CSS.backBtn}
					style={{
						borderColor: darkMode
							? "rgba(var(--white-rgb), 0.3)"
							: "rgba(var(--black-rgb), 0.3)",
						color: darkMode ? "var(--white)" : "var(--black)"
					}}
					id="project-back-btn"
					onClick={() => {
						//@ts-ignore
						if (ref.current.scrollTop === 0) {
							setLocalState({
								projectPopup: false
							});
						} else {
							gsap.fromTo(
								scrollRef,
								{
									//@ts-ignore
									current: ref.current.scrollTop
								},
								{
									duration: 1,
									ease: "power1.inOut",
									current: 0,
									onUpdate: function () {
										//@ts-ignore
										if (!ref.current) return;
										//@ts-ignore
										ref.current.scrollTo({
											top: scrollRef.current
										});
									},
									onComplete: () => {
										setTimeout(() => {
											setLocalState({
												projectPopup: false
											});
										}, 100);
									}
								}
							);
						}
					}}>
					Back to Projects
				</div>
			</div>
		</Popup>
	);
});

export default Project;
