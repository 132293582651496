import { useRef, useState, useEffect } from "react";
import gsap from "gsap";

import Carrousel from "@components/carrousel";

import Slide1 from "/images/projects/les-bordes-estate/sc1.webp";
import Slide2 from "/images/projects/les-bordes-estate/sc2.webp";
import Slide3 from "/images/projects/les-bordes-estate/sc3.webp";
import Slide4 from "/images/projects/les-bordes-estate/sc4.webp";
import Slide5 from "/images/projects/les-bordes-estate/sc5.webp";
import Slide6 from "/images/projects/les-bordes-estate/sc6.webp";
import { useStore } from "@state/store";
import CSS from "./css/digital.module.css";
import SHARED from "./css/shared.module.css";

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6];

function DigitalExperience({ ...props }) {
	const { sectionIndex, darkMode } = useStore(s => ({
		sectionIndex: s.sectionIndex,
		darkMode: s.darkMode
	}));
	const active = sectionIndex === 4;
	const wrapperRef = useRef<HTMLDivElement>(null);

	return (
		<div
			{...props}
			className={`${SHARED.sectionWrapper} ${CSS.digWrapper}`}
			ref={wrapperRef}>
			<div
				className={`${SHARED.copyWrapperToLeft} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)",
					marginBottom: "20px",
					textAlign: "left"
				}}>
				<h1 className={`projectTitle`} style={{ width: "100%" }}>
					An Elevated Digital Experience with Luna
				</h1>
			</div>
			<div
				className={`${CSS.carrouselWrapper} ${SHARED.transitionReveal}`}
				style={{
					opacity: active ? 1 : 0,
					filter: active ? "blur(0px)" : "blur(10px)"
				}}>
				<Carrousel images={slides} />
			</div>
			<p className={`projectCopy ${CSS.paragraph}`}>
				To elevate the project’s reach, we designed a bespoke Luna platform
				application, which brought the residences to life in both physical and
				digital spaces. This seamless tool allowed potential buyers to explore
				the development in stunning detail—whether they were visiting the sales
				gallery or engaging with the brand online. By bridging the gap between
				digital convenience and personal interaction, the Luna platform extended
				the Six Senses experience far beyond the boundaries of traditional
				marketing.
			</p>
		</div>
	);
}

export default DigitalExperience;
