import { useEffect, useMemo, useRef } from 'react'
import gsap from 'gsap'

type TStaggeredTextProps = {
  text: string
  cue: boolean
  duration?: number
  delay?: number
  anmScale?: boolean
  exitScale?: number
  blur?: boolean
  customGsap?: (x: boolean, t: HTMLSpanElement[]) => gsap.TweenVars
  [x: string]: unknown
}

function StaggeredText(props: TStaggeredTextProps) {
  const {
    text,
    cue,
    duration = 1,
    delay = 0,
    anmScale = false,
    exitScale = 0.5,
    blur = false,
    customGsap = undefined,
    ...rest
  } = props
  const textArray = useMemo(() => text.split(''), [text])
  const charRefs = useRef<HTMLSpanElement[]>([])

  useEffect(() => {
    if (!charRefs.current.length) return
    gsap.to(
      charRefs.current,
      customGsap
        ? { ...customGsap(cue, charRefs.current) }
        : {
            opacity: cue ? 1 : 0,
            scale: anmScale ? (cue ? 1 : exitScale) : 1,
            filter: blur ? (cue ? 'blur(0px)' : 'blur(5px)') : 'blur(0px)',
            stagger: duration * 0.08,
            ease: 'power2.inOut',
            duration,
            delay,
          },
    )
  }, [cue, text])
  return (
    <div {...rest}>
      {textArray.map((char, i) => {
        return (
          <span
            key={`char${i}`}
            style={{
              display: 'inline-block',
              opacity: 0,
            }}
            ref={(node) => {
              if (node) {
                charRefs.current[i] = node
              }
            }}
          >
            {char === ' ' ? '\u205f' : char}
          </span>
        )
      })}
    </div>
  )
}

export default StaggeredText
