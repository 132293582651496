import { useState, useLayoutEffect } from 'react'

import StaggeredText from '@components/staggered-text'

import { useStore } from '@state/store'
import { projects } from '@data/projectsData'
import CSS from './menuTitle.module.css'

function ProjectMenuTitle() {
  const { activeProject } = useStore((s) => ({
    activeProject: s.activeProject,
  }))
  const [firstLoad, setFirstLoad] = useState(true)
  const [activeTitle, setActiveTitle] = useState(projects[activeProject].label)
  const [activeVisible, setActiveVisible] = useState(true)
  const [animatedTitle, setAnimatedTitle] = useState(projects[activeProject].label)
  const [animatedVisible, setAnimatedVisible] = useState(false)

  const duration = 0.3

  useLayoutEffect(() => {
    if (activeVisible) {
      setAnimatedTitle(projects[activeProject].label)
    } else {
      setActiveTitle(projects[activeProject].label)
    }
    if (firstLoad) {
      setFirstLoad(false)
      return
    }
    if (activeVisible) {
      setActiveVisible(false)
      setTimeout(() => {
        setAnimatedVisible(true)
      }, duration * 500)
    } else {
      setAnimatedVisible(false)
      setTimeout(() => {
        setActiveVisible(true)
      }, duration * 500)
    }
  }, [activeProject])

  return (
    <div className={CSS.titleWrapper}>
      <StaggeredText
        text={activeTitle}
        cue={activeVisible}
        className={CSS.title}
        duration={duration}
      />
      <StaggeredText
        text={animatedTitle}
        cue={animatedVisible}
        className={CSS.title}
        duration={duration}
      />
    </div>
  )
}

export default ProjectMenuTitle
