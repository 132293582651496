import { useState, useCallback, useEffect, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";

import CSS from "./carrousel.module.css";
import { useStore } from "@state/store";

function Carrousel({ images }) {
	const { darkMode } = useStore(s => ({
		darkMode: s.darkMode
	}));
	const [activeIndex, setActiveIndex] = useState(0);
	const indexRef = useRef(0);
	const [emblaRef, emblaApi] = useEmblaCarousel({
		loop: true,
		dragThreshold: 10
	});
	const onSelect = useCallback(emblaApi => {
		const currentId = emblaApi.selectedScrollSnap();
		setActiveIndex(currentId);
	}, []);

	useEffect(() => {
		if (emblaApi) {
			if (activeIndex !== emblaApi.selectedScrollSnap()) {
				emblaApi.scrollTo(activeIndex);
			}
		}
		indexRef.current = activeIndex;
	}, [activeIndex]);

	function handleArrows(e) {
		if (e.key === "ArrowRight") {
			const nextIndex =
				indexRef.current === images.length - 1 ? 0 : indexRef.current + 1;
			indexRef.current = nextIndex;
			setActiveIndex(indexRef.current);
			return;
		}
		if (e.key === "ArrowLeft") {
			const nextIndex =
				indexRef.current === 0 ? images.length - 1 : indexRef.current - 1;
			indexRef.current = nextIndex;
			setActiveIndex(indexRef.current);
			return;
		}
	}

	useEffect(() => {
		if (emblaApi) {
			emblaApi.on("select", onSelect);
			document.addEventListener("keyup", handleArrows);
		}
		return () => {
			document.removeEventListener("keyup", handleArrows);
		};
	}, [emblaApi]);

	return (
		<div className={CSS.carrouselWrapper} ref={emblaRef}>
			<div className={CSS.carrousel}>
				{images.map((src, i) => {
					return (
						<div key={`carrousel-img-${src}`} className={CSS.carrouselSlide}>
							<img
								src={src}
								style={{
									transform: activeIndex === i ? "scale(1.9)" : "scale(1.4)",
									opacity: activeIndex === i ? 1 : 0.8,
									filter:
										activeIndex === i
											? "blur(0px) saturate(1)"
											: "blur(2px) saturate(1)"
								}}
							/>
						</div>
					);
				})}
			</div>
			<div
				className={CSS.gradient}
				style={{
					backgroundColor: darkMode ? "var(--black)" : "var(--white)"
				}}
			/>
      <span>
        Drag or use arrow keys.
      </span>
		</div>
	);
}

export default Carrousel;
