import { useState, useLayoutEffect, useEffect } from "react";
import gsap from "gsap";

import { useStore } from "@state/store";

function NavLogo({ animated = true, ...props }) {
	const { darkMode, navOpen } = useStore(s => ({
		darkMode: s.darkMode,
		navOpen: s.navOpen
	}));
	const [firstRender, setFirstRender] = useState(true);
	const timeline = gsap.timeline();

	useLayoutEffect(() => {
		if (firstRender || !animated) return;
		if (!navOpen) {
			//@ts-ignore
			timeline.to(
				".menu-bar",
				{
					attr: i => {
						return {
							x1: collapsed[lines[i].char].x1,
							y1: collapsed[lines[i].char].y1,
							x2: collapsed[lines[i].char].x2,
							y2: collapsed[lines[i].char].y2
						};
					}
				},
				0
			);
			//@ts-ignore
			timeline.to(
				".menu-bar",
				{
					attr: i => {
						return {
							x1: menuIcon[lines[i].char].x1,
							y1: menuIcon[lines[i].char].y1,
							x2: menuIcon[lines[i].char].x2,
							y2: menuIcon[lines[i].char].y2,
							"stroke-width": "2"
						};
					}
				},
				">"
			);
		} else {
			//@ts-ignore
			timeline.to(
				".menu-bar",
				{
					attr: i => {
						return {
							x1: menuIcon[lines[i].char].x1,
							y1: menuIcon[lines[i].char].y1,
							x2: menuIcon[lines[i].char].x2,
							y2: menuIcon[lines[i].char].y2
						};
					}
				},
				0
			);
			//@ts-ignore
			timeline.to(
				".menu-bar",
				{
					attr: i => {
						return {
							x1: collapsed[lines[i].char].x1,
							y1: collapsed[lines[i].char].y1,
							x2: collapsed[lines[i].char].x2,
							y2: collapsed[lines[i].char].y2
						};
					}
				},
				">"
			);
			//@ts-ignore
			timeline.to(
				".menu-bar",
				{
					attr: i => {
						return {
							x1: lines[i].x1,
							y1: lines[i].y1,
							x2: lines[i].x2,
							y2: lines[i].y2,
							"stroke-width": "1"
						};
					}
				},
				">"
			);
		}
	}, [navOpen]);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}
	}, []);
	return (
		<svg x="0" y="0" viewBox="0 0 100 100" {...props}>
			{lines.map((obj, i) => {
				return (
					<line
						key={`logo-${obj.char}-${i}`}
						id={`${obj.char}-${i}`}
						x1={animated ? menuIcon[obj.char].x1 : obj.x1}
						y1={animated ? menuIcon[obj.char].y1 : obj.y1}
						x2={animated ? menuIcon[obj.char].x2 : obj.x2}
						y2={animated ? menuIcon[obj.char].y2 : obj.y2}
						fill="none"
						stroke={darkMode ? "var(--white)" : "var(--black)"}
						strokeWidth={animated ? 1 : 0.25}
						className={"menu-bar"}
					/>
				);
			})}
		</svg>
	);
}

export default NavLogo;

const lines = [
	{ char: "V", anm: false, x1: 19.5, y1: 67.2, x2: 25, y2: 67 },
	{ char: "V", anm: true, x1: 6.5, y1: 34, x2: 19.7, y2: 67.1 },
	{ char: "V", anm: true, x1: 8.2, y1: 34, x2: 21.4, y2: 67.1 },
	{ char: "V", anm: true, x1: 10.2, y1: 34, x2: 23.4, y2: 67.1 },
	{ char: "V", anm: true, x1: 34.3, y1: 34, x2: 21.1, y2: 67.1 },
	{ char: "V", anm: true, x1: 36.2, y1: 34, x2: 23, y2: 67.1 },
	{ char: "V", anm: true, x1: 38, y1: 34, x2: 24.8, y2: 67.1 },
	{ char: "M", anm: false, x1: 41, y1: 34.2, x2: 47.5, y2: 34.2 },
	{ char: "M", anm: false, x1: 69, y1: 34.2, x2: 75.5, y2: 34.2 },
	{ char: "M", anm: true, x1: 41.2, y1: 67.2, x2: 41.2, y2: 34.2 },
	{ char: "M", anm: true, x1: 43.3, y1: 67.2, x2: 43.3, y2: 34.2 },
	{ char: "M", anm: true, x1: 45.2, y1: 67.2, x2: 45.2, y2: 34.2 },
	{ char: "M", anm: true, x1: 71.3, y1: 67.2, x2: 71.3, y2: 34.2 },
	{ char: "M", anm: true, x1: 73.3, y1: 67.2, x2: 73.3, y2: 34.2 },
	{ char: "M", anm: true, x1: 75.3, y1: 67.2, x2: 75.3, y2: 34.2 },
	{ char: "M", anm: true, x1: 56.3, y1: 64.8, x2: 43.5, y2: 34.1 },
	{ char: "M", anm: true, x1: 58.3, y1: 64.8, x2: 45.5, y2: 34.1 },
	{ char: "M", anm: true, x1: 60.1, y1: 64.8, x2: 47.3, y2: 34.1 },
	{ char: "M", anm: true, x1: 60.2, y1: 64.8, x2: 73.1, y2: 34.1 },
	{ char: "M", anm: true, x1: 58.2, y1: 64.8, x2: 71, y2: 34.1 },
	{ char: "M", anm: true, x1: 56.4, y1: 64.8, x2: 69.2, y2: 34.1 },
	{ char: "M", anm: false, x1: 56, y1: 64.7, x2: 60.5, y2: 64.7 },
	{ char: "I", anm: true, x1: 83.3, y1: 67.2, x2: 83.3, y2: 34.2 },
	{ char: "I", anm: true, x1: 85.3, y1: 67.2, x2: 85.3, y2: 34.2 },
	{ char: "I", anm: true, x1: 87.3, y1: 67.2, x2: 87.3, y2: 34.2 }
];

const collapsed = {
	V: { x1: 22.3, y1: 34.2, x2: 22.3, y2: 67.2 },
	M: { x1: 58.2, y1: 67.2, x2: 58.2, y2: 34.2 },
	I: { x1: 85.3, y1: 67.2, x2: 85.3, y2: 34.2 }
};

const menuIcon = {
	V: { x1: 70, y1: 37.5, x2: 30, y2: 37.5 },
	M: { x1: 20, y1: 50, x2: 80, y2: 50 },
	I: { x1: 30, y1: 62.5, x2: 70, y2: 62.5 }
};
