import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { projectKeys } from "@data/projectsData";

export interface ScrollStore {
	SCROLL_VALUE: number;
	setScrollValue: (value: number) => void;

	SCROLL_VELOCITY: number;
	setScrollVelocity: (value: number) => void;
}

export const useScrollStore = create(
	subscribeWithSelector<ScrollStore>(set => ({
		SCROLL_VALUE: 0,
		setScrollValue: (value: number) => set({ SCROLL_VALUE: value }),
		SCROLL_VELOCITY: 0,
		setScrollVelocity: (value: number) => set({ SCROLL_VELOCITY: value })
	}))
);

interface StoreTypes {
	nav: boolean;
	setNav: (value: boolean) => void;
	darkMode: boolean;
	setDarkMode: (value: boolean) => void;
	section: string | null;
	setSection: (value: string | null) => void;
	navOpen: boolean;
	setNavOpen: (v: boolean) => void;
	lenisInstance: any;
	setLenisInstance: (v: any) => void;
	navigating: boolean;
	setNavigating: (v: boolean) => void;
	navDurationSec: number;
	handleNav: (callback: () => void) => void;
	scrolling: boolean;
	setScrolling: (v: boolean) => void;
	activeProject: null | string;
	setActiveProject: (v: string | null) => void;
	projectPopup: boolean;
	sectionIndex: number;
	launchPopupSeen: boolean;
}

type SetLocalState = {
	setLocalState: (state: Partial<StoreTypes>) => void;
};

export const useStore = create<StoreTypes & SetLocalState>()((set, get) => {
	return {
		nav: false,
		setNav: v => set({ nav: v }),
		darkMode: true,
		setDarkMode: v => set({ darkMode: v }),
		section: null,
		setSection: v => set({ section: v }),
		navOpen: false,
		setNavOpen: v => set({ navOpen: v }),
		lenisInstance: null,
		setLenisInstance: v => set({ lenisInstance: v }),
		navigating: true,
		setNavigating: v => set({ navigating: v }),
		navDurationSec: 0.8,
		handleNav: callback => {
			set({ navigating: true });
			setTimeout(() => {
				callback();
			}, get().navDurationSec * 1150);
		},
		scrolling: false,
		setScrolling: v => set({ scrolling: v }),
		activeProject: projectKeys[0],
		projectPopup: false,
		sectionIndex: 0,
		setActiveProject: v =>
			set({ activeProject: v === get().activeProject ? null : v }),
		setLocalState: stateToUpdate => {
			set({ ...stateToUpdate });
		},
		launchPopupSeen: true
	};
});
