import { useStore } from "@state/store";

export default function InstagramSVG({ ...props }) {
	const darkMode = useStore(state => state.darkMode);
	return (
		<svg
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M35.4163 4.16629C41.1618 4.18304 45.8158 8.83705 45.8337 14.5837V35.4163C45.8158 41.1618 41.1618 45.8158 35.4163 45.8337H14.5837C8.83705 45.8158 4.18304 41.1618 4.16629 35.4163V14.5837C4.18304 8.83705 8.83705 4.18304 14.5837 4.16629H35.4163ZM35.4163 0H14.5837C6.5625 0 0 6.5625 0 14.5837V35.4163C0 43.4375 6.5625 50 14.5837 50H35.4163C43.4375 50 50 43.4375 50 35.4163V14.5837C50 6.5625 43.4375 0 35.4163 0Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
			<path
				d="M38.5413 14.5837C36.8147 14.5837 35.4163 13.1842 35.4163 11.4587C35.4163 9.73215 36.8147 8.33371 38.5413 8.33371C40.2667 8.33371 41.6663 9.73215 41.6663 11.4587C41.6696 13.1808 40.2768 14.5791 38.5547 14.5836C38.5491 14.5836 38.5446 14.5836 38.5401 14.5836L38.5413 14.5837Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
			<path
				d="M25 16.6663C29.6016 16.6663 33.3337 20.3973 33.3337 25C33.3337 29.6016 29.6016 33.3337 25 33.3337C20.3973 33.3337 16.6663 29.6016 16.6663 25C16.6708 20.3984 20.3984 16.6708 25 16.6663ZM25 12.5C18.096 12.5 12.5 18.096 12.5 25C12.5 31.9029 18.096 37.5 25 37.5C31.9029 37.5 37.5 31.9029 37.5 25C37.5 18.096 31.9029 12.5 25 12.5Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
		</svg>
	);
}
