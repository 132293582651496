"use-client";
import { useEffect, useState, useRef, useLayoutEffect } from "react";

import { useStore } from "@state/store";
import { projects, projectKeys } from "@data/projectsData";
import CSS from "./menu.module.css";
import gsap from "gsap";

function ProjectsMenu() {
	const [projectIndex, setProjectIndex] = useState(0);
	const [switching, setSwitching] = useState(false);
	const switchingRef = useRef(false);
	const indexRef = useRef(0);
	const { darkMode, projectPopup, setLocalState } = useStore(s => ({
		darkMode: s.darkMode,
		projectPopup: s.projectPopup,
		setLocalState: s.setLocalState
	}));
	const { down } = useGestures({
		onSwipeUp,
		onSwipeDown,
		enabled: !projectPopup
	});
	const maxIndex = projectKeys.length - 1;
	const borderColor = !darkMode
		? "rgba(var(--black-rgb), 0.3)"
		: "rgba(var(--white-rgb), 0.3)";

	function onSwipeUp() {
		if (switchingRef.current) return;
		indexRef.current = indexRef.current === 0 ? maxIndex : indexRef.current - 1;
		setProjectIndex(indexRef.current);
		debounce();
	}
	function onSwipeDown() {
		if (switchingRef.current) return;
		indexRef.current = indexRef.current === maxIndex ? 0 : indexRef.current + 1;
		setProjectIndex(indexRef.current);
		debounce();
	}

	function debounce() {
		setSwitching(true);
		switchingRef.current = true;
		setTimeout(() => {
			setSwitching(false);
			switchingRef.current = false;
		}, 800);
	}

	function handleKeys(e) {
		if (switchingRef.current) return;
		if (e.key === "ArrowDown") {
			const nextIndex =
				indexRef.current === maxIndex ? 0 : indexRef.current + 1;
			indexRef.current = nextIndex;
			setProjectIndex(indexRef.current);
			debounce();
			return;
		}
		if (e.key === "ArrowUp") {
			const nextIndex =
				indexRef.current === 0 ? maxIndex : indexRef.current - 1;
			indexRef.current = nextIndex;
			setProjectIndex(indexRef.current);
			debounce();
			return;
		}
		if (e.key === "Enter") {
			setLocalState({
				projectPopup: true
			});
			return;
		}
	}

	useEffect(() => {
		const target = document.getElementById("project-menu-marker");
		if (!target) return;
		const projectBtn = document.querySelector(
			`[data-project-id = "${projectIndex}"]`
		) as HTMLDivElement;
		gsap.to(target, {
			y: projectBtn.offsetTop,
			duration: 0.2,
			onComplete: () => {
				setLocalState({
					activeProject: projectKeys[projectIndex]
				});
			}
		});
		indexRef.current = projectIndex;
	}, [projectIndex]);

	useLayoutEffect(() => {
		if (projectPopup) {
			document.removeEventListener("keyup", handleKeys);
		} else {
			document.addEventListener("keyup", handleKeys);
		}
		return () => {
			document.removeEventListener("keyup", handleKeys);
		};
	}, [projectPopup]);
	return (
		<div className={CSS.menuWrapper}>
			{projectKeys.map((key, i) => {
				return (
					<div
						key={`menu-btn-${key}`}
						className={CSS.menuItem}
						data-project-id={i}>
						<h1
							onClick={() => {
								if (switching) return;
								setProjectIndex(i);
								debounce();
							}}>
							{projects[key].label}
						</h1>
					</div>
				);
			})}
			<div
				className={`${CSS.menuItem} ${CSS.marker}`}
				id="project-menu-marker"
				style={{
					borderImage: `linear-gradient(to right, ${borderColor} 10%, transparent 11%, transparent 60%, ${borderColor} 62%) 0.9`
				}}>
				{/* <span
					onClick={() => {
						setLocalState({
							projectPopup: true
						});
					}}
					style={{
						color: !darkMode ? "var(--black)" : "var(--white)"
					}}>
					EXPLORE »
				</span> */}
			</div>
		</div>
	);
}

export default ProjectsMenu;

function useGestures({
	onSwipeUp = undefined,
	onSwipeDown = undefined,
	onClick = undefined,
	enabled = true,
	wheelEnabled = true
}) {
	const [down, setDown] = useState(false);
	const wheelEventTimeout = useRef(null);
	const wheelStart = useRef(0);
	const wheelElapsed = useRef(0);
	const wheelDir = useRef(null);
	const downRef = useRef(false);
	const downTimeRef = useRef(0);
	const deltaY = useRef(0);
	const timeThreshold = 50;
	const clickYThreshold = 20;
	const swipeYThreshold = 75;
	const isMac = navigator?.platform.toUpperCase().indexOf("MAC") >= 0;

	function onPointerDown(e) {
		setDown(true);
		downRef.current = true;
		downTimeRef.current = e.timeStamp;
		deltaY.current = e.clientY;
	}

	function onPointerMove(e) {
		e.preventDefault();
		if (!downRef.current) return;
	}

	function onPointerUp(e) {
		setDown(false);
		downRef.current = false;
		deltaY.current = deltaY.current - e.clientY;
		const timeCondition = e.timeStamp - downTimeRef.current < timeThreshold;
		const distanceCondition = Math.abs(deltaY.current) < clickYThreshold;
		const isClick = timeCondition || distanceCondition;
		const isSwipe = Math.abs(deltaY.current) > swipeYThreshold;
		if (isClick) {
			onClick && onClick();
		}
		if (isSwipe) {
			if (deltaY.current < 0) {
				onSwipeDown && onSwipeDown();
			} else {
				onSwipeUp && onSwipeUp();
			}
		}
		resetRefs();
	}

	function onWheel(e) {
		if (!wheelEnabled) return;
		e.preventDefault();
		clearTimeout(wheelEventTimeout.current);

		if (wheelEventTimeout.current === null) {
			wheelStart.current = e.timeStamp;
		}

		wheelElapsed.current = e.timeStamp - wheelStart.current;
		wheelDir.current = isMac ? e.deltaY > 0 : e.deltaY < 0;
		if (wheelElapsed.current > 1000) {
			if (wheelDir.current) {
				onSwipeUp && onSwipeUp();
			} else {
				onSwipeDown && onSwipeDown();
			}
			wheelStart.current = e.timeStamp;
		}

		wheelEventTimeout.current = setTimeout(() => {
			if (wheelDir.current) {
				onSwipeUp && onSwipeUp();
			} else {
				onSwipeDown && onSwipeDown();
			}
			resetRefs();
		}, 50);
	}

	function resetRefs() {
		wheelEventTimeout.current = null;
		wheelDir.current = null;
		wheelStart.current = 0;
		wheelElapsed.current = 0;
		downTimeRef.current = 0;
		deltaY.current = 0;
	}

	useEffect(() => {
		const body = document.getElementsByTagName("body")[0];
		if (!body) return;
		const prevTouchAction = body.style.touchAction;
		if (enabled) {
			body.style.touchAction = "none";
			window.document.addEventListener("pointerdown", onPointerDown);
			window.document.addEventListener("pointermove", onPointerMove);
			window.document.addEventListener("pointerup", onPointerUp);
			window.document.addEventListener("wheel", onWheel, { passive: false });
		}

		return () => {
			body.style.touchAction = prevTouchAction;
			window.document.removeEventListener("pointerdown", onPointerDown);
			window.document.removeEventListener("pointermove", onPointerMove);
			window.document.removeEventListener("pointerup", onPointerUp);
			window.document.removeEventListener("wheel", onWheel);
		};
	}, [enabled]);
	return {
		down,
		onPointerDown,
		onPointerMove,
		onPointerUp
	};
}
