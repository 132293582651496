import { useEffect, useState, useRef, ReactNode } from "react";

import CSS from "./popup.module.css";

function Popup({
	openState,
	setOpenState,
	children,
	durationMS,
	closeButton,
	closeColor,
	onClose,
	className = "",
	...props
}: {
	openState: boolean;
	setOpenState: (v: boolean) => void;
	children: ReactNode;
	durationMS: number;
	closeButton?: ReactNode;
	closeColor?: string;
	onClose?: () => void;
	className?: string;
	[x: string]: unknown;
}) {
	const [visible, setVisible] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);

	function handleRef(node) {
		if (popupRef.current === null) {
			node.style.transitionDuration = `${durationMS}ms`;
			setTimeout(() => {
				node.style.opacity = "1";
			}, 100);
			popupRef.current = node;
		} else {
			return;
		}
	}

	function handleClose() {
		popupRef.current.style.opacity = "0";
		setTimeout(() => {
			setVisible(false);
			setTimeout(() => {
				setOpenState(false);
				onClose && onClose();
				popupRef.current = null;
			}, durationMS);
		}, durationMS);
	}

	useEffect(() => {
		if (visible) {
			popupRef.current.style.opacity = "0";
			setTimeout(() => {
				setVisible(openState);
				popupRef.current = null;
			}, durationMS + 10);
		} else {
			setVisible(openState);
		}
	}, [openState]);

	return (
		visible && (
			<div
				ref={node => node && handleRef(node)}
				className={CSS.popupWrapper + className}
				{...props}>
				{children}
				{closeButton ? (
					<div onClick={handleClose} className={CSS.close}>
						{closeButton}
					</div>
				) : (
					<div onClick={handleClose} className={CSS.close}>
						<CloseIcon strokeWidth={7} strokeColor={closeColor ?? "#FFFFFF"} />
					</div>
				)}
			</div>
		)
	);
}

export default Popup;

type TSVGPropTypes = {
	strokeWidth: number;
	strokeColor: string;
	bgColor?: string;
	[x: string]: any;
};

function CloseIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<circle cx="50" cy="50" r="50" fill={bgColor ?? "none"} />
			<line
				x1="5"
				y1="5"
				x2="95"
				y2="95"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="95"
				y1="5"
				x2="5"
				y2="95"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
	);
}
