import { useStore } from "@state/store";

export default function EmailSVG({ ...props }) {
	const darkMode = useStore(state => state.darkMode);
	return (
		<svg
			width="63"
			height="50"
			viewBox="0 0 63 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M56.25 0H6.25C2.8125 0 0.03125 2.8125 0.03125 6.25L0 43.75C0 47.1875 2.8125 50 6.25 50H56.25C59.6875 50 62.5 47.1875 62.5 43.75V6.25C62.5 2.8125 59.6875 0 56.25 0ZM56.25 12.5L31.25 28.125L6.25 12.5V6.25L31.25 21.875L56.25 6.25V12.5Z"
				fill={!darkMode ? "var(--black)" : "#fff"}
			/>
		</svg>
	);
}
