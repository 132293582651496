import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import gsap from "gsap";

import { projects } from "@data/projectsData";
import { useStore } from "@state/store";
import CSS from "./control.module.css";
import { useRef } from "react";

function ProjectControl({ sections }) {
	const { sectionIndex, activeProject, darkMode, setLocalState } = useStore(
		s => ({
			sectionIndex: s.sectionIndex,
			activeProject: s.activeProject,
			darkMode: s.darkMode,
			setLocalState: s.setLocalState
		})
	);
	const scrollRef = useRef(0);
	const controlerRef = useRef<HTMLDivElement>(null);
	const scrollPromptRef = useRef<HTMLDivElement>(null);
	const indexRef = useRef(0);

	function handleClick(index) {
		const target = document.getElementById("project-container");
		if (!target) return;
		const projectSection = document.querySelector(
			`[data-project-section = "${index}"]`
		) as HTMLDivElement;
		const scrollAmount = projectSection.offsetTop;
		// return
		gsap.fromTo(
			scrollRef,
			{
				current: target.scrollTop
			},
			{
				duration: 1,
				ease: "power1.inOut",
				current: scrollAmount,
				onUpdate: function () {
					if (!target) return;
					target.scrollTo({
						top: scrollRef.current
					});
				}
			}
		);
	}

	function handleIntersection(entries) {
		entries.forEach(elm => {
			if (elm.isIntersecting) {
				const index = elm.target.getAttribute("data-project-section");
				setLocalState({
					sectionIndex: Number(index)
				});
			}
		});
	}

	function handleScroll(e) {
		const scrollY = e.target.scrollTop;
		const mappedOpacity = gsap.utils.mapRange(0, innerHeight, 0, 1, scrollY);
		if (mappedOpacity >= 1) return;
		gsap.set(controlerRef.current, {
			autoAlpha: mappedOpacity
		});
		gsap.set(scrollPromptRef.current, {
			opacity: 1 - mappedOpacity
		});
	}

	function handleSectionChange(e, sections) {
		const markerY = e.target.scrollTop + innerHeight * 0.5;
		const currentNode = sections.filter(node => {
			return (
				markerY >= node.offsetTop &&
				markerY <= node.offsetTop + node.clientHeight
			);
		});
		const index = Number(currentNode[0]?.getAttribute("data-project-section"));
		if (!isNaN(index) && index !== indexRef.current) {
			indexRef.current = index;
			setLocalState({
				sectionIndex: indexRef.current
			});
		}
	}

	useLayoutEffect(() => {
		const sections = Array.from(
			document.querySelectorAll<HTMLDivElement>(`[data-project-section]`)
		);
		const scrolllTarget = document.getElementById("project-container");
		scrolllTarget.addEventListener("scroll", e =>
			handleSectionChange(e, sections)
		);
		scrolllTarget.addEventListener("scroll", handleScroll);

		return () => {
			scrolllTarget.removeEventListener("onscroll", handleScroll);
			scrolllTarget.removeEventListener("scroll", e =>
				handleSectionChange(e, sections)
			);
		};
	}, []);
	return (
		<Fragment>
			<div className={CSS.controlWrapper} ref={controlerRef}>
				<div className={CSS.menuWrapper}>
					<div key={`control-btn-video`} className={CSS.menuItem}>
						<h1
							style={{
								opacity: 1,
								fontWeight: 600,
								textDecoration: "underline",
								color: darkMode ? "var(--white)" : "var(--black)"
							}}
							onClick={() => {
								handleClick(0);
							}}>
							{projects[activeProject].label} by VMI
						</h1>
					</div>
					{sections.map((obj, i) => {
						return (
							<div key={`control-btn-${i}`} className={CSS.menuItem}>
								<h1
									style={{
										opacity: sectionIndex === i + 1 ? 1 : 0.3,
										color: darkMode ? "var(--white)" : "var(--black)"
									}}
									onClick={() => {
										handleClick(i + 1);
									}}>
									{obj.label}
								</h1>
							</div>
						);
					})}
				</div>
				<span className={CSS.pagination}>
					{sectionIndex} / {sections.length}
				</span>
			</div>
			<div
				className={CSS.scrollPrompt}
				// style={{
				// 	borderColor: darkMode
				// 		? "rgba(var(--white-rgb), 0.3)"
				// 		: "rgba(var(--black-rgb), 0.3)"
				// }}
				ref={scrollPromptRef}>
				<span>Scroll.</span>
			</div>
		</Fragment>
	);
}

export default ProjectControl;
