import { useState, useRef, useLayoutEffect } from "react";
import gsap from "gsap";

import { projects } from "@data/projectsData";
import { useStore } from "@state/store";
import CSS from "./menuImg.module.css";

function ProjectMenuImg() {
	const { activeProject, darkMode } = useStore(s => ({
		activeProject: s.activeProject,
		darkMode: s.darkMode
	}));
	const [firstLoad, setFirstLoad] = useState(true);
	const [activeSRC, setActiveSRC] = useState(projects[activeProject].mainImg);
	const [animatedSRC, setAnimatedSRC] = useState(
		projects[activeProject].mainImg
	);
	const anmImg = useRef<HTMLImageElement>(null);

	const openClip = "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)";
	const closeClip = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";

	useLayoutEffect(() => {
		setAnimatedSRC(projects[activeProject].mainImg);
		if (firstLoad) {
			gsap.set(anmImg.current, { clipPath: closeClip });
			setFirstLoad(false);
		} else {
			gsap.fromTo(
				anmImg.current,
				{ clipPath: closeClip },
				{
					clipPath: openClip,
					onComplete: () => {
						setActiveSRC(projects[activeProject].mainImg);
					},
					duration: 0.6,
					ease: "power1.inOut"
				}
			);
		}
	}, [activeProject]);
	return (
		<div className={CSS.wrapper}>
			<img src={activeSRC} alt="" />
			<img src={animatedSRC} ref={anmImg} alt="" />
		</div>
	);
}

export default ProjectMenuImg;
