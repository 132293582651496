import Landing from "@pages/landing"
import Contact from "@pages/contact"
import Projects from "@pages/projects"

const routes = [
	{
		name: "HOME",
		path: "/",
		onNav: true,
		element: Landing
	},
	{
		name: "COTACT",
		path: "/contact",
		onNav: true,
		element: Contact
	},
	{
		name: "PROJECTS",
		path: "/projects",
		onNav: true,
		element: Projects
	}
]

export default routes
