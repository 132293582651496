import { useStore } from "@state/store";
import React from "react";

export default function VMILogo({ ...props }) {
	const { darkMode, navOpen } = useStore(s => ({
		darkMode: s.darkMode,
		navOpen: s.navOpen
	}));
	return (
		<svg
			width="428"
			height="185"
			viewBox="0 0 428 185"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M394.386 173.976H394.936V11.28H394.386V173.976ZM403.51 173.976H404.06V11.28H403.51V173.976ZM412.634 173.976H413.184V11.28H412.634V173.976ZM91.6865 164.003L95.3929 173.696H87.9811L91.6766 164.003H91.6865ZM105.648 173.696H97.1153L159.032 11.3738L158.513 11.1762L96.5241 173.706H95.994L91.9878 163.237L149.908 11.3738L149.388 11.1762L91.6865 162.459L33.7669 11.1762L33.2479 11.3738L91.3852 163.225L87.3895 173.696H86.8601L24.6429 11.1762L24.1239 11.3738L86.2685 173.696H77.7362L15.519 11.1762L15 11.3738L77.2898 174.08L77.3624 174.256H106.032L168.156 11.3738L167.637 11.1762L105.648 173.706V173.696ZM267.865 152.642L272.12 142.502L276.387 152.642L272.132 162.78L267.865 152.642ZM199.316 11.55H205.917V27.2353L199.316 11.55ZM206.479 28.5512V11.55H207.849L267.273 152.642L263.018 162.78L206.467 28.5512H206.479ZM216.972 11.55L271.83 141.787L267.574 151.926L208.44 11.55H216.972ZM272.431 141.787L327.082 11.55H335.614L276.698 151.926L272.422 141.787H272.431ZM277.01 152.642L336.206 11.55H337.11V29.7123L281.265 162.79L276.989 152.652L277.01 152.642ZM337.669 11.55H344.738L337.669 28.3864V11.55ZM326.708 11L272.12 141.072L217.397 11.1663L217.325 11H187.66V173.976H188.21V11.55H196.784V173.976H197.334V11.55H198.704L205.907 28.6649V173.976H206.457V29.9823L262.738 163.609L262.996 164.222L267.554 153.367L272.12 164.222L276.678 153.367L281.244 164.222L337.11 31.1434V173.976H337.66V29.8161L345.32 11.55H346.223V173.976H346.773V11.55H355.347V173.976H355.897V11H326.688H326.708Z"
				fill={darkMode ? "white" : "black"}
				stroke={darkMode ? "white" : "black"}
				strokeWidth="1.42115"
			/>
		</svg>
	);
}
