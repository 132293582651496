export type TProjectData = {
	[x: string]: {
		id: string;
		year: string;
		label: string;
		location: [string, string];
		video: string;
		mainImg: string;
		mainPar: string[];
		accentColor: string;
	};
};

export const projects: TProjectData = {
	"les-bordes-estate": {
		id: "les-bordes-estate",
		year: "2024",
		label: "Les Bordes Estate",
		location: ["Loire Valley,", "France"],
		video: "/videos/les-bordes-estate/nature.mp4",
		mainImg: "/images/projects/les-bordes-estate/hero.webp",
		mainPar: [
			"We were directly approached by Round Shield and the Six Senses team to bring their vision to life for a premier development in the Loire Valley, France. The project aimed to highlight a stunning new collection of timeless residences, crafted under the renowned Six Senses brand. Through in-depth discovery and storyboarding sessions with both the designers and Six Senses, we gained a comprehensive understanding of the design concept and brand ethos."
			// "Six Senses approached us with a clear vision: to showcase their new collection of branded residences in the Loire Valley, a development that seamlessly blends luxury with the beauty of France’s premier wine region."
		],
		accentColor: "#173959"
	},
	"norton-folgate": {
		id: "norton-folgate",
		year: "2024",
		label: "Elder Yard",
		location: ["Spitalfields,", "United Kingdom"],
		video: "/videos/norton-folgate/NF_main.mp4",
		mainImg: "/images/projects/norton-folgate/main.webp",
		mainPar: [
			"Norton Folgate, just a short walk from Liverpool Street and Shoreditch High Street stations, presents a fully renovated commercial space with carefully curated architecture and state of the art sustainability features.",
			"Our scope on the project was to produce a series of films as well as an interactive application that effectively communicated and presented the new development and its main characteristics to British Land’s potential national and international tenants."
		],
		accentColor: "#EB683C"
	},
	"1515-surf": {
		id: "1515-surf",
		year: "2024",
		label: "1515 Surf",
		location: ["Coney Island,", "United States"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/1515-surf/pool.webp",
		mainPar: [
			"We were approached to help bring Surf 1515 in Coney Island to life – a project that celebrates the area’s rich history and vibrant energy. Drawing inspiration from Woody Allen’s Wonder Wheel, we crafted a series of visuals that embraced the nostalgic charm of the boardwalk while introducing modern design elements. Through detailed collaboration with the design team, we developed a story-driven visual suite featuring colorful skies, moody vignettes, and pops of character, seamlessly blending past and present to create a compelling vision for this beachfront development."
			// "1515 Surf Avenue, located in the heart of Coney Island, needed a visual experience that captured the area’s unique culture and energy."
		],
		accentColor: "#EB683C"
	},
	roshn: {
		id: "roshn",
		year: "2024",
		label: "Roshn",
		location: ["Riyadh,", "Saudi Arabia"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/roshn/roshn-main.webp",
		mainPar: [
			"We partnered with ROSHN to visualize their ambitious masterplans in Saudi Arabia, showcasing the scale and vision of their developments through cutting-edge technology. By leveraging Unreal Engine, we created immersive experiences that brought the masterplans to life, allowing stakeholders to explore residential neighborhoods, amenities, and surrounding environments in real time. From dynamic lighting transitions to interactive tools for viewing availability, our work combined storytelling with technical precision, aligning with ROSHN's commitment to innovation and community-focused living."
			// 	"Norton Folgate, just a short walk from Liverpool Street and Shoreditch High Street stations, presents a fully renovated commercial space with carefully curated architecture and state of the art sustainability features.",
			// 	"Our scope on the project was to produce a series of films as well as an interactive application that effectively communicated and presented the new development and its main characteristics to British Land’s potential national and international tenants."
		],
		accentColor: "#EB683C"
	},
	origin: {
		id: "origin",
		year: "2024",
		label: "Origin",
		location: ["Toronto,", "Canada"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/origin/origin-main.webp",
		mainPar: [
			`We collaborated on Origin by Marlin Spring, a landmark development in Canada that redefines urban living with a focus on wellness, connectivity, and design excellence. Through a tailored visual strategy, we brought the project's vision to life, creating a suite of high-fidelity content that captured the architectural details, vibrant amenities, and surrounding community. By combining photorealistic renders, immersive experiences, and storytelling elements, we showcased the development's modern lifestyle offering, positioning Origin as a cornerstone of innovation in the Canadian market.`
			// "Norton Folgate, just a short walk from Liverpool Street and Shoreditch High Street stations, presents a fully renovated commercial space with carefully curated architecture and state of the art sustainability features.",
			// "Our scope on the project was to produce a series of films as well as an interactive application that effectively communicated and presented the new development and its main characteristics to British Land’s potential national and international tenants."
		],
		accentColor: "#EB683C"
	},
	tsx: {
		id: "tsx",
		year: "2024",
		label: "TSX",
		location: ["New York,", "United States"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/tsx/tsx-main.webp",
		mainPar: [
			"We partnered with TSXE and developer L&L to create an immersive metaverse experience that brought the essence of Times Square to life. Our team meticulously designed a fully 3D version of the iconic location, ensuring accuracy and visual impact. To enhance the experience, we green-screened talent, capturing dynamic performances that were integrated into CGI stills, simulating what it would look like to perform on stage. We also added cinemagraphs to enhance visual storytelling."
			// "VMI Studio was tasked with creating an immersive digital experience for TSX Broadway, a state-of-the-art development in the heart of Times Square, New York."
		],
		accentColor: "#EB683C"
	},
	thresholds: {
		id: "thresholds",
		year: "2024",
		label: "Thresholds",
		location: ["London,", "United Kingdom"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/thresholds/22.webp",
		mainPar: [
			"When YBA artist Mat Collishaw approached VMI Studio to bring to life one of the earliest exhibitions of photography by British scientist William Henry Fox Talbot in 1839, he had limited knowledge of what technology could achieve. After conducting discovery sprints and workshops, we developed a multi-user haptic experience that could be hosted at Somerset House."
			// "Norton Folgate, just a short walk from Liverpool Street and Shoreditch High Street stations, presents a fully renovated commercial space with carefully curated architecture and state of the art sustainability features.",
			// "Our scope on the project was to produce a series of films as well as an interactive application that effectively communicated and presented the new development and its main characteristics to British Land’s potential national and international tenants."
		],
		accentColor: "#EB683C"
	},
	"the-boardwalk": {
		id: "the-boardwalk",
		year: "2024",
		label: "The Boardwalk",
		location: ["Coney Island,", "United States"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/the-boardwalk/the-boardwalk-main.webp",
		mainPar: [
			"We were engaged to bring The Boardwalk in Long Beach to life, a development that perfectly balances coastal living with contemporary design. Drawing inspiration from its waterfront setting, we crafted a series of visuals that captured the essence of the lifestyle—serene ocean views, vibrant community spaces, and modern interiors. Through detailed storytelling and immersive content, we highlighted the development’s seamless connection to the shoreline, creating a compelling vision that reflects the charm and energy of Long Beach’s iconic boardwalk."
			// "The Boardwalk project presented a unique opportunity to bring a transformative waterfront development to life. Collaborating with If Studio, who led the branding, we focused on delivering an immersive digital and physical experience that would capture the essence of this vibrant, community-centric destination."
		],
		accentColor: "#EB683C"
	},
	"720": {
		id: "720",
		year: "2024",
		label: "720",
		location: ["New York,", "United States"],
		video: "/videos/bg.mp4",
		mainImg: "/images/projects/720/720-main.webp",
		mainPar: [
			"In collaboration with Corcoran Sunshine Marketing Group and esteemed designer Thomas Juul-Hansen, we embarked on a comprehensive marketing initiative for 720 West End Avenue, a reimagined pre-war landmark on Manhattan's Upper West Side. Originally designed in 1927 by architect Emery Roth, this 17-story building has been transformed into 131 elegant residences, blending classic character with contemporary design. "
			// "720 West End Avenue, a distinguished residential development on Manhattan’s Upper West Side, required a digital experience that reflected its historic charm and modern luxury. "
		],
		accentColor: "#EB683C"
	}
};

export const projectKeys = Object.keys(projects);
